import { useState } from 'react';
import { Icon } from '@fluentui/react';
import { printData } from 'app/common';
import { FormattedMessage } from 'app/i18n';
import { CollaborationWithContactInfo } from 'app/pages/my-audience/contacts';

interface ReviewCollaborationInformationProps {
  collaborationDetails: CollaborationWithContactInfo;
}

export const ReviewCollaborations = ({ collaborationDetails }: ReviewCollaborationInformationProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const collaboration = collaborationDetails.collaboration;
  return (
    <>
      <div className="custom-accordion" key="sending-overview-title" onClick={() => setIsExpanded((state) => !state)}>
        <div className="custom-accordion-head">
          <div className="custom-accordion-head-content">
            <span className="custom-accordion-head-icon">
              <Icon iconName="ChevronDown" className={isExpanded && ' expanded'} />
            </span>
            <span className="custom-accordion-head-icon-title">{`${collaborationDetails.firstName} ${collaborationDetails.lastName}`}</span>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="wizard-panel-finish-main-content">
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              {' '}
              <FormattedMessage id={'add-menu-create-mo.additional-function-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.jobTitle?.name)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.additional-detail-function-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.jobDescription)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.additional-phone-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.landlinePhoneNumber?.value)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.additional-mobile-phone-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.mobilePhoneNumber?.value)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.additional-email-label'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.emailAddress)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.additional-checkout-zip'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.postalCode)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.additional-checkout-country'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.country)}</span>
          </div>
          <div className="wizard-panel-finish-main-box">
            <span className="wizard-panel-finish-main-label">
              <FormattedMessage id={'add-menu-create-mo.additional-checkout-country'} />
            </span>
            <span className="wizard-panel-finish-main-input">{printData(collaboration.city)}</span>
          </div>
        </div>
      )}
    </>
  );
};
