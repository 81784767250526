export interface HighlightedTextProps {
  textLine: string;
  term: string;
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({ textLine, term }) => {
  const parts = [];
  const words = textLine.split(' ');

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const index = word.toLowerCase().indexOf(term);

    if (index === -1) {
      parts.push(word + ' ');
      continue;
    }

    parts.push(
      <span key={i}>
        {word
          .split(new RegExp(`(?=${term})|(?<=${term})`, 'gi'))
          .map((wordPiece, idx) => (wordPiece.toLowerCase() === term ? <b key={idx}>{wordPiece}</b> : wordPiece))}
        &nbsp;
      </span>
    );
  }

  return <>{parts}</>;
};
