import { useMemo, useState } from 'react';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  IDetailsRowProps,
  IDropdownOption,
  IObjectWithKey,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import { Button, Checkbox, EditIcon, Tooltip, TrashCanIcon } from '@fluentui/react-northstar';
import { EntitiesPerPage, useAppDispatch, useAppSelector, useAudienceHeader, usePersistentSelection } from 'app/common';
import { ProfilePicture } from 'app/common/components/ProfilePicture';
import { useIntl } from 'app/i18n';
import {
  openDeleteRecipientDialog,
  selectEditingRecipientsIds,
  selectIsLoadingRecipients,
  selectRecipientsPageNumber,
  selectSelectedRecipientsIdsPerPage,
  selectSelectedSendingRecipients,
  setEditingRecipientsIds,
  setSelectedRecipientsIds,
  setSelectedRecipientsIdsIfNotLoading,
  updateSendingRecipientContactDataRequested
} from 'app/pages/my-activities/sending-wizard';
import { Recipient, UpdateSendingRecipientInput } from 'app/pages/my-activities/sendings';
import { Collaboration, Contact, OwnContactData } from 'app/pages/my-audience/contacts';
import { WarningMessage } from 'app/common/components/warning-message/WarningMessage';

interface AudienceTableProps {
  disabled: boolean;
}

interface TableRows {
  key: string;
  id: string;
  name: string;
  emailAddress: string;
  contactData: string;
}

const getEmailAddressOfCollaboration = (contact: Contact, collaboration: Collaboration): string => {
  return contact.collaborations.find((c) => c.id === collaboration.id).emailAddress;
};

const getContactDataOptions = (collaborations: Collaboration[], recipient: Recipient): IDropdownOption[] => {
  return [...collaborations.map((col) => ({ key: col.id + recipient.id, text: col.medium.name })), { key: recipient.contact.id, text: 'Own contact data' }];
};

export const AudienceTable = ({ disabled }: AudienceTableProps) => {
  const { formatMessage } = useIntl();
  const { header } = useAudienceHeader();
  const dispatch = useAppDispatch();

  const isLoadingRecipients = useAppSelector<boolean>(selectIsLoadingRecipients);
  const recipients = useAppSelector<Recipient[]>(selectSelectedSendingRecipients);
  const editingRecipientsIds = useAppSelector<string[]>(selectEditingRecipientsIds);
  const recipientsPageNumber = useAppSelector<number>(selectRecipientsPageNumber);
  const selectedRecipientsIdsPerPage = useAppSelector<EntitiesPerPage<string>>(selectSelectedRecipientsIdsPerPage);
  const [hoveredRecipientId, setHoveredRecipientId] = useState('');

  const selection = useMemo(
    () =>
      new Selection<IObjectWithKey>({
        onSelectionChanged: () => {
          const selectedTableRows = selection.getSelection() as TableRows[];

          if (selectedTableRows.includes(undefined)) {
            return;
          }

          dispatch(setSelectedRecipientsIdsIfNotLoading(selectedTableRows.map((row) => row.id)));
        },
        selectionMode: SelectionMode.multiple
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getRecipientId = <T,>(entity: T): string => {
    const recipientId = entity as string;
    return recipientId;
  };

  usePersistentSelection({
    tableRows: recipients,
    entitiesPerPage: selectedRecipientsIdsPerPage,
    selection,
    currentPageNumber: recipientsPageNumber,
    getEntityId: getRecipientId
  });

  const selectNewContactData = (recipient: Recipient, selectedMediumName: string) => {
    const contact = recipient.contact;
    const ownContactData = contact.ownContactData;
    const collaboration = contact.collaborations.find((c) => c.medium.name === selectedMediumName);

    const emailAddress = collaboration?.id ? getEmailAddressOfCollaboration(contact, collaboration) : ownContactData.emailAddress;

    dispatch(updateSendingRecipientContactDataRequested(new UpdateSendingRecipientInput(recipient.id, collaboration?.id ?? '', emailAddress)));
  };

  const addEditingRecipientId = (recipientId: string) => {
    dispatch(setEditingRecipientsIds([...editingRecipientsIds, recipientId]));
  };

  const removeRecipientFromSending = (recipient: Recipient) => {
    selection.setAllSelected(false);
    selection.setKeySelected(recipient.id, true, true);
    dispatch(setSelectedRecipientsIds([recipient.id]));
    dispatch(openDeleteRecipientDialog());
  };

  const renderEmailOnlyRecipient = (recipient: Recipient) => {
    return {
      key: recipient.id,
      id: recipient.id,
      onDelete: () => removeRecipientFromSending(recipient),
      name: '',
      emailAddress: recipient.emailAddress,
      contactData: '',
      hoverActions: (
        <>
          {hoveredRecipientId === recipient.id && (
            <Button text icon={<TrashCanIcon />} onClick={() => removeRecipientFromSending(recipient)} disabled={disabled} />
          )}
        </>
      )
    };
  };

  const renderHoverActions = (hoverItemId: string, recipient: Recipient) => {
    if (hoverItemId !== recipient.id) return <></>;

    return (
      <>
        <Tooltip
          subtle={false}
          pointing
          trigger={<Button text icon={<TrashCanIcon />} onClick={() => removeRecipientFromSending(recipient)} disabled={disabled} />}
          content={formatMessage({ id: 'button-tooltips.delete-contact-data' })}
          position="below"
        />
        <Tooltip
          subtle={false}
          pointing
          trigger={<Button text icon={<EditIcon />} onClick={() => addEditingRecipientId(recipient.id)} disabled={disabled} />}
          content={formatMessage({ id: 'button-tooltips.edit-contact-data' })}
          position="below"
        />
      </>
    );
  };

  const renderContactData = (
    isEditingRecipient: boolean,
    recipient: Recipient,
    collaborations: Collaboration[],
    selectedContactData: Collaboration | OwnContactData
  ) => {
    return isEditingRecipient ? (
      <div key={recipient.id}>
        <Dropdown
          placeholder={formatMessage({ id: 'sending-profile.table-row-collaboration-input' })}
          options={getContactDataOptions(collaborations, recipient)}
          disabled={disabled}
          onChange={(_, option) => {
            selectNewContactData(recipient, option.text);
            dispatch(setEditingRecipientsIds(editingRecipientsIds.filter((id) => id !== recipient.id)));
          }}
        />
      </div>
    ) : (
      (selectedContactData as Collaboration)?.medium?.name
    );
  };

  const rows = [
    ...recipients.map((recipient: Recipient) => {
      const recipientCollaborationId = recipient?.collaborationId;
      const { id: contactId, firstName, lastName, ownContactData, collaborations } = recipient.contact;

      if (!contactId) {
        return renderEmailOnlyRecipient(recipient);
      }

      const selectedContactData = recipientCollaborationId ? collaborations?.find((c) => c.id === recipientCollaborationId) : ownContactData;
      const isEditingRecipient = editingRecipientsIds.includes(recipient.id);

      const maybeEmailAddress = recipient?.emailAddress || (
        <WarningMessage warningText={formatMessage({ id: 'add-contacts-wizard.contact-has-no-email-address' })} />
      );

      return {
        key: recipient.id,
        id: recipient.id,
        onDelete: () => removeRecipientFromSending(recipient),
        name: `${firstName} ${lastName}`,
        emailAddress: maybeEmailAddress,
        contactData: renderContactData(isEditingRecipient, recipient, collaborations, selectedContactData),
        hoverActions: renderHoverActions(hoveredRecipientId, recipient)
      };
    })
  ];

  const renderCheck = ({ item }: IDetailsRowProps) => {
    const isHoveringOverItem = hoveredRecipientId === item.id;
    const isItemSelected = selection.getSelection().includes(item);
    const shouldDisplayCheckbox = isHoveringOverItem || isItemSelected;

    return (
      <div className="row-header-wrap">
        {shouldDisplayCheckbox ? <Checkbox checked={isItemSelected} /> : <ProfilePicture name={item.name} imageUrl={item.profilePictureUrl} size="small" />}
      </div>
    );
  };

  const renderRow = (props: IDetailsRowProps) => {
    return (
      <div onMouseEnter={() => setHoveredRecipientId(props.item.id)} onMouseLeave={() => setHoveredRecipientId('')}>
        <DetailsRow {...props} onRenderCheck={() => renderCheck(props)} />
      </div>
    );
  };

  return (
    <MarqueeSelection selection={selection} isDraggingConstrainedToRoot={true}>
      <ShimmeredDetailsList
        items={rows}
        columns={header}
        setKey="sendingProfileAudienceTableSet"
        enableShimmer={isLoadingRecipients}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="select row"
        onRenderRow={renderRow}
      />
    </MarqueeSelection>
  );
};
