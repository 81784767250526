import { app } from '@microsoft/teams-js';
import { Avatar, Button, ChatIcon, EmailIcon, MeetingNewIcon, PhoneArrowIcon, TeamsMonochromeIcon, Tooltip } from '@fluentui/react-northstar';
import { useAppDispatch, useAppSelector } from 'app/common';
import {
  openNumberDialog,
  selectActivityEmailAddress,
  selectSelectedContactHasTeamsEnabled,
  selectSelectedContact
} from 'app/pages/my-audience/contact-profile';
import { Collaboration, Contact, OwnContactData } from 'app/pages/my-audience/contacts';
import { useIntl } from 'app/i18n';

export const ContactQuickActions = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const activityEmailAddress = useAppSelector<string>(selectActivityEmailAddress);
  const selectedContactHasTeamsEnabled = useAppSelector<boolean>(selectSelectedContactHasTeamsEnabled);
  const selectedContact = useAppSelector<Contact>(selectSelectedContact);
  const primaryContactData: Collaboration | OwnContactData = selectedContact.ownContactData?.isPrimary
    ? selectedContact.ownContactData
    : selectedContact.collaborations.find((c) => c.isPrimary);

  const hasEmailAddress = !!primaryContactData?.emailAddress;
  const hasMobilePhoneNumber = !!primaryContactData?.mobilePhoneNumber?.value;
  const hasLandlinePhoneNumber = !!primaryContactData?.landlinePhoneNumber?.value;

  const hasPhoneNumber = hasMobilePhoneNumber || hasLandlinePhoneNumber;
  const hasDefaultPhoneNumber = hasPhoneNumber && (primaryContactData?.mobilePhoneNumber?.isPrimary || primaryContactData?.landlinePhoneNumber?.isPrimary);

  const openChooseNumberDialog = () => {
    dispatch(openNumberDialog());
  };

  const defaultPhoneNumber = (): string => {
    if (primaryContactData?.mobilePhoneNumber?.isPrimary) {
      return primaryContactData.mobilePhoneNumber.value;
    }

    return primaryContactData?.landlinePhoneNumber?.value;
  };

  const handleTeamsCallClick = () => {
    if (app.isInitialized()) app.openLink(`https://teams.microsoft.com/l/call/0/0?users=${primaryContactData?.emailAddress}`);
  };

  const handleTeamsMessageClick = () => {
    if (app.isInitialized()) app.openLink(`https://teams.microsoft.com/l/chat/0/0?users=${primaryContactData?.emailAddress}`);
  };

  const handleMakeAppointmentClick = () => {
    if (app.isInitialized()) {
      const startTime = new Date();
      const startTimeEncoded = encodeURIComponent(startTime.toISOString());

      const endTime = new Date(startTime.getTime() + 30 * 60000);
      const endTimeEncoded = encodeURIComponent(endTime.toISOString());

      app.openLink(
        `https://teams.microsoft.com/l/meeting/new?attendees=${primaryContactData?.emailAddress}&startTime=${startTimeEncoded}&endTime=${endTimeEncoded}`
      );
    }
  };

  const onSendEmail = () => {
    window.open(`mailto:${primaryContactData?.emailAddress}?bcc=${activityEmailAddress}`, '_blank');
  };

  return (
    <div className="quick-actions-wrap">
      <Tooltip
        subtle={false}
        pointing
        trigger={
          hasEmailAddress ? (
            <Avatar className="quick-action" icon={<EmailIcon />} onClick={onSendEmail} />
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<EmailIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'contacts-quick-actions.send-email' })}
      />
      <Tooltip
        subtle={false}
        pointing
        trigger={
          !hasPhoneNumber ? (
            <div>
              <Button icon={<PhoneArrowIcon />} className="quick-action" circular disabled />
            </div>
          ) : hasDefaultPhoneNumber ? (
            <a className="quick-action" href={`tel:+${defaultPhoneNumber()}`} target="_blank" rel="noreferrer">
              <Avatar icon={<PhoneArrowIcon />} />
            </a>
          ) : (
            <Avatar icon={<PhoneArrowIcon />} className="quick-action" onClick={openChooseNumberDialog} />
          )
        }
        position="below"
        content={formatMessage({ id: 'contacts-quick-actions.phone-call' })}
      />
      <Tooltip
        subtle={false}
        pointing
        trigger={
          selectedContactHasTeamsEnabled ? (
            <Avatar icon={<TeamsMonochromeIcon />} onClick={handleTeamsCallClick} className="quick-action" />
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<TeamsMonochromeIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'contacts-quick-actions.teams-call' })}
      />
      <Tooltip
        trigger={
          selectedContactHasTeamsEnabled ? (
            <Avatar icon={<ChatIcon />} className="quick-action" onClick={handleTeamsMessageClick} />
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<ChatIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'contacts-quick-actions.teams-chat' })}
        subtle={false}
        pointing
      />
      <Tooltip
        trigger={
          selectedContactHasTeamsEnabled ? (
            <Avatar icon={<MeetingNewIcon />} className="quick-action" onClick={handleMakeAppointmentClick} />
          ) : (
            <div>
              <Button primary circular className="quick-action" icon={<MeetingNewIcon />} disabled />
            </div>
          )
        }
        position="below"
        content={formatMessage({ id: 'contacts-quick-actions.meeting' })}
        subtle={false}
        pointing
      />
    </div>
  );
};
