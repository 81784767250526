import { useAppDispatch, useAppSelector, useContactLimitationWarning, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import { selectRecipientsToAdd, addRecipientsToSendingRequested, closeAddContactWizard, selectSelectedSending } from 'app/pages/my-activities/sending-wizard';
import { Recipient } from 'app/pages/my-activities/sendings';
import { AddContactsFooter, AddContactsSearch, AddContactsTable } from 'app/pages/my-activities/add-audience-wizard';

export const AddContacts = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { checkForContactNumberLimit } = useContactLimitationWarning();

  const recipientsToAdd = useAppSelector<Recipient[]>(selectRecipientsToAdd);
  const selectedSending = useAppSelector(selectSelectedSending);

  const totalNumberOfRecipients = selectedSending.numberOfRecipients + recipientsToAdd.length;

  const onClickFinishButton = () => {
    if (recipientsToAdd?.length) {
      checkForContactNumberLimit({ totalNumberOfContacts: totalNumberOfRecipients, successCallback: addRecipientsToSendingAndCloseWizard });
    }
  };

  const addRecipientsToSendingAndCloseWizard = () => {
    dispatch(addRecipientsToSendingRequested(recipientsToAdd));
    dispatch(closeAddContactWizard());
  };

  return (
    <div className="wizard-content">
      <WizardIntro title={formatMessage({ id: 'add-contacts-wizard.header' })} description={formatMessage({ id: 'add-contacts-wizard.description' })} />
      <div className="wizard-content-main wizard-content-audience">
        <AddContactsSearch />
        <AddContactsTable />
      </div>
      <AddContactsFooter onAddButtonClick={onClickFinishButton} />
    </div>
  );
};
