import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { closeAddFromListPanel, selectIsAddFromListPanelOpened } from 'app/pages/my-activities/sending-wizard';
import { AddContactFromList } from 'app/pages/my-activities/add-audience-wizard';

export const AddContactsFromListsWizard = () => {
  const dispatch = useAppDispatch();

  const isPanelOpened = useAppSelector<boolean>(selectIsAddFromListPanelOpened);

  const handleDismissButtonClick = () => {
    dispatch(closeAddFromListPanel());
  };

  return (
    <WizardWrap
      isWizardOpened={isPanelOpened}
      onDismissButtonClick={handleDismissButtonClick}
      customWidth="700px"
      classModifier="narrow"
      hasBackArrow={true}
      onArrowButtonClick={handleDismissButtonClick}
      isLightDismiss={false}
    >
      <div className="wizard-panel-main">
        <AddContactFromList />
      </div>
    </WizardWrap>
  );
};
