import { SheetConfig } from '@flatfile/api/api';

export const contactsBlueprint: SheetConfig = {
  name: 'Contacts',
  slug: 'Contacts',
  fields: [
    {
      key: 'salutation',
      label: 'Salutation',
      type: 'string'
    },
    {
      key: 'first_name',
      label: 'First Name',
      type: 'string'
    },
    {
      key: 'last_name',
      label: 'Last Name',
      type: 'string'
    },
    {
      key: 'protocol_title',
      label: 'Protocol Title',
      type: 'string'
    },
    {
      key: 'comment',
      label: 'Comment',
      type: 'string'
    },
    {
      key: 'profile_picture_url',
      label: 'Profile Picture URL',
      type: 'string'
    },
    {
      key: 'landline_phone_number',
      label: 'Landline Phone Number',
      type: 'string'
    },
    {
      key: 'mobile_phone_number',
      label: 'Mobile Phone Number',
      type: 'string'
    },
    {
      key: 'email_address',
      label: 'Email Address',
      type: 'string'
    },
    {
      key: 'fax',
      label: 'Fax',
      type: 'string'
    },
    {
      key: 'street',
      label: 'Street',
      type: 'string'
    },
    {
      key: 'city',
      label: 'City',
      type: 'string'
    },
    {
      key: 'postal_code',
      label: 'Postal Code',
      type: 'number'
    },
    {
      key: 'country',
      label: 'Country',
      type: 'string'
    },
    {
      key: 'collaboration_medium_name',
      label: 'Collaboration Medium Name',
      type: 'string'
    },
    {
      key: 'collaboration_medium_type',
      label: 'Collaboration Medium Type',
      type: 'string'
    },
    {
      key: 'job_title_name',
      label: 'Job Title Name',
      type: 'string'
    },
    {
      key: 'job_title_description',
      label: 'Job Title Description',
      type: 'string'
    }
  ]
};
