import { useAppDispatch, useAppSelector, useContactLimitationWarning, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addContactsFromCompanyRequested,
  closeAddFromCompanyPanel,
  selectContactsFromCompanyToAdd,
  selectSelectedSending
} from 'app/pages/my-activities/sending-wizard';
import { AddContactFromCompanyFooter, AddContactFromCompanySearch, AddContactFromCompanyTable } from 'app/pages/my-activities/add-audience-wizard';

export const AddContactFromCompany = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { checkForContactNumberLimit } = useContactLimitationWarning();
  const aadContactsToAdd = useAppSelector(selectContactsFromCompanyToAdd);
  const selectedSending = useAppSelector(selectSelectedSending);

  const totalNumberOfRecipients = selectedSending.numberOfRecipients + aadContactsToAdd.length;

  const handleAddButtonClick = () => {
    checkForContactNumberLimit({ totalNumberOfContacts: totalNumberOfRecipients, successCallback: addContactsToSendingAndClosePanel });
  };

  const addContactsToSendingAndClosePanel = () => {
    dispatch(addContactsFromCompanyRequested());
    dispatch(closeAddFromCompanyPanel());
  };

  return (
    <div className="wizard-content">
      <WizardIntro
        title={formatMessage({ id: 'add-contacts-from-company-wizard.header' })}
        description={formatMessage({ id: 'add-contacts-from-company-wizard.description' })}
      />
      <div className="wizard-content-main wizard-content-audience">
        <AddContactFromCompanySearch />
        <AddContactFromCompanyTable />
      </div>
      <AddContactFromCompanyFooter onAddButtonClick={handleAddButtonClick} />
    </div>
  );
};
