import FlatfileListener from '@flatfile/listener';
import augureLogoDark from 'assets/images/augure-logo-dark-version.png';
import dataListener from 'app/common/components/flatfile/listeners/OnSubmitListener';
import closeImportWindowOnUploadEvent from 'app/common/components/flatfile/events/CloseImportOnUploadEvent';
import fieldsValidation from 'app/common/components/flatfile/listeners/FieldsValidationListener';
import { useEffect } from 'react';
import { useIntl } from 'app/i18n';
import { contactsBlueprint } from 'app/common/components/flatfile/blueprints/blueprint';
import { Sheet, Space, useFlatfile } from '@flatfile/react';
import { useAppDispatch, showAlertNotification } from 'app/common';

const listener = new FlatfileListener();

export const FlatfileSpace = ({ loggedInUserId, tenantId }) => {
  const { openPortal, setListener } = useFlatfile();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  closeImportWindowOnUploadEvent();

  useEffect(() => {
    fieldsValidation(listener);
    dataListener(loggedInUserId, tenantId, listener);
    setListener(listener);
    openPortal();
  }, []);

  return (
    <Space
      config={{
        name: 'Contacts',
        namespace: 'portal',
        metadata: {
          theme: {
            root: {
              primaryColor: '#3b2fc9',
              textColor: 'white'
            },
            sidebar: {
              logo: augureLogoDark
            }
          }
        }
      }}
    >
      <Sheet
        config={contactsBlueprint}
        onSubmit={async ({ sheet }) => {
          await sheet.allData();
          dispatch(showAlertNotification(formatMessage({ id: 'import-contacts.feedback-message' })));
        }}
      />
    </Space>
  );
};
