import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AvatarDetailsInput,
  AvatarDetailsWithSavedSearchId,
  createContact,
  createSearchSuggestionCategory,
  FilterItem,
  SearchSuggestionCategory,
  Tag
} from 'app/common';
import {
  AddContactDocument,
  AddContactMutation,
  AddMediaResortDocument,
  AddMediaResortMutation,
  AddTopicDocument,
  AddTopicMutation,
  AddTagToContactsDocument,
  AddTagToContactsMutation,
  Contact as GqlContact,
  ContactInput,
  ContactNameFilterSuggestionsDocument,
  ContactNameFilterSuggestionsQuery,
  FetchBulkContactsAvatarDetailsDocument,
  FetchBulkContactsAvatarDetailsQuery,
  FetchContactAvatarDetailsDocument,
  FetchContactAvatarDetailsQuery,
  FetchContactsBySearchTextDocument,
  FetchContactsBySearchTextQuery,
  FetchContactSearchSuggestionsDocument,
  FetchContactSearchSuggestionsQuery,
  LanguageFilterSuggestionsDocument,
  LanguageFilterSuggestionsQuery,
  MediaResortInput,
  MediumNameFilterSuggestionsDocument,
  MediumNameFilterSuggestionsQuery,
  RemoveContactsDocument,
  RemoveContactsMutation,
  RemoveTagFromContactsDocument,
  RemoveTagFromContactsMutation,
  ResortFilterSuggestionsDocument,
  ResortFilterSuggestionsQuery,
  RoleFilterSuggestionsDocument,
  RoleFilterSuggestionsQuery,
  TagNameFilterSuggestionsDocument,
  TagNameFilterSuggestionsQuery,
  ContactsSortOption,
  ContactsSortOrder,
  ContactsTagInput,
  ContactsMediaResortsInput,
  ContactsTopicsInput,
  TopicInput,
  AddJobTitleMutation,
  AddJobTitleDocument,
  RemoveJobTitleMutation,
  RemoveJobTitleDocument,
  JobTitleInput,
  CountryFilterSuggestionsDocument,
  CountryFilterSuggestionsQuery,
  AddMediaResortsToContactsMutation,
  AddMediaResortsToContactsDocument,
  AddTopicsToContactsDocument,
  AddTopicsToContactsMutation,
  AddListsToContactsMutation,
  AddListsToContactsDocument
} from 'app/common/graphql/generated/graphql-gateway';
import { Contact, ISortingInput, JobTitle, MediaResort, MediumType, MediumTypeName, PageOfContacts, Topic } from 'app/pages/my-audience/contacts';
import { AvatarDetails } from 'app/pages/my-audience/saved-searches';

export const fetchContactsByQueryParamsPaged = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  filterItems: FilterItem[],
  pageNumber: number,
  pageSize: number,
  sortingInput: ISortingInput
): Promise<PageOfContacts> => {
  const response = await client.query<FetchContactsBySearchTextQuery>({
    query: FetchContactsBySearchTextDocument,
    variables: {
      searchText: searchText,
      filterItems: filterItems,
      skip: (pageNumber - 1) * pageSize,
      take: pageSize,
      sortingInput: {
        contactsSortOption: sortingInput.sortOption,
        sortOrder: sortingInput.sortOrder
      }
    }
  });

  return new PageOfContacts(
    response.data.contacts.totalCount,
    response.data.contacts.contacts.map((contact) => createContact(contact as GqlContact))
  );
};

export const fetchContactAvatarDetails = async (
  client: ApolloClient<NormalizedCacheObject>,
  searchText: string,
  filterItems: FilterItem[]
): Promise<{ avatarDetails: AvatarDetails[]; totalCount: number }> => {
  const response = await client.query<FetchContactAvatarDetailsQuery>({
    query: FetchContactAvatarDetailsDocument,
    variables: {
      searchText: `${searchText}*`,
      filterItems: filterItems,
      skip: 0,
      take: 1000,
      sortingInput: { contactsSortOption: ContactsSortOption.ContactName, sortOrder: ContactsSortOrder.Asc }
    }
  });

  return {
    avatarDetails: response.data.contacts.contacts.map((contact) => new AvatarDetails(`${contact.firstName} ${contact.lastName}`, contact.profilePictureUrl)),
    totalCount: response.data.contacts.totalCount
  };
};

export const fetchBulkContactAvatarDetails = async (
  client: ApolloClient<NormalizedCacheObject>,
  avatarDetails: AvatarDetailsInput[]
): Promise<AvatarDetailsWithSavedSearchId[]> => {
  const response = await client.query<FetchBulkContactsAvatarDetailsQuery>({
    query: FetchBulkContactsAvatarDetailsDocument,
    variables: {
      contactQueryInput: avatarDetails
    }
  });

  return response.data.contactsAvatarDetails.map(
    (contactsQueryInput) =>
      new AvatarDetailsWithSavedSearchId(
        contactsQueryInput.savedSearchId,
        contactsQueryInput.pageOfContacts.contacts.map((contact) => new AvatarDetails(`${contact.firstName} ${contact.lastName}`, contact.profilePictureUrl)),
        contactsQueryInput.pageOfContacts.totalCount
      )
  );
};

export const fetchContactSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string): Promise<SearchSuggestionCategory[]> => {
  const response = await client.query<FetchContactSearchSuggestionsQuery>({
    query: FetchContactSearchSuggestionsDocument,
    variables: {
      searchText: searchText
    }
  });

  return response.data.searchSuggestions.map((ssc) => createSearchSuggestionCategory(ssc));
};

export const addContact = async (client: ApolloClient<NormalizedCacheObject>, contact: Contact): Promise<Contact> => {
  const topicsInput: ContactsTopicsInput[] = contact.topics?.map((topic) => {
    const topicInput: ContactsTopicsInput = {
      topicId: topic.id,
      topicName: topic.name
    };

    return topicInput;
  });
  const mediaResortsInput: ContactsMediaResortsInput[] = contact.mediaResorts?.map((mediaResort) => {
    const mediaResortInput: ContactsMediaResortsInput = {
      mediaResortId: mediaResort.id,
      mediaResortName: mediaResort.name
    };

    return mediaResortInput;
  });

  const input: ContactInput = {
    salutation: contact.salutation,
    firstName: contact.firstName,
    lastName: contact.lastName,
    profilePictureUrl: contact.profilePictureUrl,
    isGloballySignedOut: contact.isGloballySignedOut,
    tags: contact.tags?.length ? contact.tags : [],
    topics: topicsInput ?? [],
    mediaResorts: mediaResortsInput ?? [],
    ownContactData: {
      address: contact.ownContactData?.address,
      blogUrl: contact.ownContactData?.blogUrl,
      city: contact.ownContactData?.city,
      country: contact.ownContactData?.country,
      emailAddress: contact.ownContactData?.emailAddress,
      facebookProfileUrl: contact.ownContactData?.facebookProfileUrl,
      fax: contact.ownContactData?.fax,
      instagramProfileUrl: contact.ownContactData?.instagramProfileUrl,
      isPrimary: contact.ownContactData?.isPrimary,
      landlinePhoneNumber: contact.ownContactData?.landlinePhoneNumber?.value
        ? {
            value: contact.ownContactData?.landlinePhoneNumber.value,
            isPrimary: contact.ownContactData?.landlinePhoneNumber.isPrimary
          }
        : undefined,
      mobilePhoneNumber: contact.ownContactData?.mobilePhoneNumber?.value
        ? {
            value: contact.ownContactData?.mobilePhoneNumber.value,
            isPrimary: contact.ownContactData?.mobilePhoneNumber.isPrimary
          }
        : undefined,
      linkedInProfileUrl: contact.ownContactData?.linkedInProfileUrl,
      postalCode: contact.ownContactData?.postalCode,
      twitterProfileUrl: contact.ownContactData?.twitterProfileUrl,
      websiteUrl: contact.ownContactData?.websiteUrl,
      youtubeProfileUrl: contact.ownContactData?.youtubeProfileUrl
    },
    collaborations: contact.collaborations?.length
      ? contact.collaborations.map((_, i) => ({
          jobTitle: contact.collaborations[i].jobTitle?.id
            ? {
                id: contact.collaborations[i].jobTitle?.id,
                dataSourceKey: contact.collaborations[i].jobTitle?.dataSourceKey,
                name: contact.collaborations[i].jobTitle?.name,
                mediumType: {
                  name: MediumTypeName(contact.collaborations[i].jobTitle?.mediumType),
                  value: contact.collaborations[i].jobTitle?.mediumType
                }
              }
            : undefined,
          medium: {
            id: contact.collaborations[i].medium.id,
            name: contact.collaborations[i].medium.name,
            type: {
              name: MediumType[contact.collaborations[i].medium.type],
              value: contact.collaborations[i].medium.type
            }
          },
          address: contact.collaborations[i].address,
          country: contact.collaborations[i].country,
          landlinePhoneNumber: contact.collaborations[i].landlinePhoneNumber?.value
            ? {
                value: contact.collaborations[i].landlinePhoneNumber.value,
                isPrimary: contact.collaborations[i].landlinePhoneNumber.isPrimary
              }
            : undefined,
          mobilePhoneNumber: contact.collaborations[i].mobilePhoneNumber?.value
            ? {
                value: contact.collaborations[i].mobilePhoneNumber.value,
                isPrimary: contact.collaborations[i].mobilePhoneNumber.isPrimary
              }
            : undefined,
          emailAddress: contact.collaborations[i].emailAddress,
          blogUrl: contact.collaborations[i].blogUrl,
          websiteUrl: contact.collaborations[i].websiteUrl,
          twitterProfileUrl: contact.collaborations[i].twitterProfileUrl,
          instagramProfileUrl: contact.collaborations[i].instagramProfileUrl,
          linkedInProfileUrl: contact.collaborations[i].linkedInProfileUrl,
          youtubeProfileUrl: contact.collaborations[i].youtubeProfileUrl,
          facebookProfileUrl: contact.collaborations[i].facebookProfileUrl,
          isPrimary: contact.collaborations[i].isPrimary,
          jobDescription: contact.collaborations[i].jobDescription,
          city: contact.collaborations[i].city,
          postalCode: contact.collaborations[i].postalCode
        }))
      : [],
    comments: contact.comments?.length ? contact.comments : [],
    blacklists: contact.blacklists?.length ? contact.blacklists : [],
    preferredLanguages: contact.preferredLanguages?.length ? contact.preferredLanguages : []
  };

  const response = await client.mutate<AddContactMutation>({
    mutation: AddContactDocument,
    variables: {
      input
    }
  });

  return createContact(response.data.addContact as GqlContact);
};

export const fetchContactNameFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<ContactNameFilterSuggestionsQuery>({
    query: ContactNameFilterSuggestionsDocument,
    variables: {
      searchText,
      take
    }
  });

  return response.data.contactNameFilterSuggestions;
};

export const fetchRoleFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<RoleFilterSuggestionsQuery>({
    query: RoleFilterSuggestionsDocument,
    variables: {
      searchText,
      take
    }
  });

  return response.data.roleFilterSuggestions;
};

export const fetchMediumNameFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<MediumNameFilterSuggestionsQuery>({
    query: MediumNameFilterSuggestionsDocument,
    variables: {
      searchText,
      take
    }
  });

  return response.data.mediumNameFilterSuggestions;
};

export const fetchResortFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<ResortFilterSuggestionsQuery>({
    query: ResortFilterSuggestionsDocument,
    variables: {
      searchText,
      take
    }
  });

  return response.data.resortFilterSuggestions;
};

export const fetchLanguageFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<LanguageFilterSuggestionsQuery>({
    query: LanguageFilterSuggestionsDocument,
    variables: {
      searchText,
      take
    }
  });

  return response.data.languageFilterSuggestions;
};

export const fetchTagNameFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<TagNameFilterSuggestionsQuery>({
    query: TagNameFilterSuggestionsDocument,
    variables: {
      searchText,
      take
    }
  });

  return response.data.tagNameFilterSuggestions;
};

export const fetchCountryFilterSuggestions = async (client: ApolloClient<NormalizedCacheObject>, searchText: string, take: number): Promise<string[]> => {
  const response = await client.query<CountryFilterSuggestionsQuery>({
    query: CountryFilterSuggestionsDocument,
    variables: {
      searchText,
      take
    }
  });

  return response.data.countryFilterSuggestions;
};

export const removeContactsByIds = async (client: ApolloClient<NormalizedCacheObject>, ids: string[]): Promise<Contact[]> => {
  const response = await client.mutate<RemoveContactsMutation>({
    mutation: RemoveContactsDocument,
    variables: {
      ids: ids
    }
  });

  return response.data.removeContacts.map((c) => createContact(c as GqlContact));
};

export const addMediaResort = async (client: ApolloClient<NormalizedCacheObject>, mediaResort: MediaResort): Promise<MediaResort> => {
  const input: MediaResortInput = {
    name: mediaResort.name
  };

  const response = await client.mutate<AddMediaResortMutation>({
    mutation: AddMediaResortDocument,
    variables: {
      input
    }
  });

  return new MediaResort(response.data.addMediaResort.id, response.data.addMediaResort.name, response.data.addMediaResort.dataSourceKey);
};

export const addTopic = async (client: ApolloClient<NormalizedCacheObject>, topic: Topic): Promise<Topic> => {
  const input: TopicInput = {
    name: topic.name
  };

  const response = await client.mutate<AddTopicMutation>({
    mutation: AddTopicDocument,
    variables: {
      input
    }
  });

  return new Topic(response.data.addTopic.id, response.data.addTopic.name, response.data.addTopic.dataSourceKey);
};

export const addTagToContacts = async (client: ApolloClient<NormalizedCacheObject>, contactIds: string[], tag: Tag): Promise<Tag> => {
  const tagInput: ContactsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<AddTagToContactsMutation>({
    mutation: AddTagToContactsDocument,
    variables: {
      contactIds: contactIds,
      input: tagInput
    }
  });

  return new Tag(response.data.addTagToContacts.id, response.data.addTagToContacts.name, '');
};

export const removeTagFromContacts = async (client: ApolloClient<NormalizedCacheObject>, contactIds: string[], tag: Tag): Promise<Tag> => {
  const tagInput: ContactsTagInput = {
    id: tag.id,
    name: tag.name
  };

  const response = await client.mutate<RemoveTagFromContactsMutation>({
    mutation: RemoveTagFromContactsDocument,
    variables: {
      contactIds: contactIds,
      input: tagInput
    }
  });

  return new Tag(response.data.removeTagFromContacts.id, response.data.removeTagFromContacts.name, '');
};

export const addMediaResortsToContacts = async (
  client: ApolloClient<NormalizedCacheObject>,
  contactsIds: string[],
  mediaResortsIds: string[]
): Promise<string[]> => {
  const response = await client.mutate<AddMediaResortsToContactsMutation>({
    mutation: AddMediaResortsToContactsDocument,
    variables: {
      contactsIds,
      mediaResortsIds
    }
  });

  return response.data.addMediaResortsToContacts;
};

export const addTopicsToContacts = async (client: ApolloClient<NormalizedCacheObject>, contactsIds: string[], topicsIds: string[]): Promise<string[]> => {
  const response = await client.mutate<AddTopicsToContactsMutation>({
    mutation: AddTopicsToContactsDocument,
    variables: {
      contactsIds,
      topicsIds
    }
  });

  return response.data.addTopicsToContacts;
};

export const addListsToContacts = async (client: ApolloClient<NormalizedCacheObject>, contactsIds: string[], listsIds: string[]): Promise<string[]> => {
  const response = await client.mutate<AddListsToContactsMutation>({
    mutation: AddListsToContactsDocument,
    variables: {
      contactsIds,
      listsIds
    }
  });

  return response.data.addListsToContacts;
};

export const addJobTitle = async (client: ApolloClient<NormalizedCacheObject>, jobTitle: JobTitle): Promise<JobTitle> => {
  const input: JobTitleInput = {
    name: jobTitle.name,
    mediumType: {
      name: MediumType[jobTitle.mediumType],
      value: jobTitle.mediumType
    },
    dataSourceKey: jobTitle.dataSourceKey
  };

  const response = await client.mutate<AddJobTitleMutation>({
    mutation: AddJobTitleDocument,
    variables: {
      input
    }
  });

  return new JobTitle(
    response.data.addJobTitle.id,
    response.data.addJobTitle.name,
    response.data.addJobTitle.dataSourceKey,
    response.data.addJobTitle.mediumType.value
  );
};

export const removeJobTitle = async (client: ApolloClient<NormalizedCacheObject>, jobTitleId: string): Promise<string> => {
  const response = await client.mutate<RemoveJobTitleMutation>({
    mutation: RemoveJobTitleDocument,
    variables: {
      jobTitleId
    }
  });

  return response.data.removeJobTitle;
};
