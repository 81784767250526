import FlatfileListener from '@flatfile/listener';
import { recordHook } from '@flatfile/plugin-record-hook';

export default function fieldsValidation(listener: FlatfileListener) {
  listener.use(
    recordHook('Contacts', (record) => {
      const fieldValue = record.get('last_name');
      if (!fieldValue) {
        record.addError('last_name', 'Last name is required');
      }
      return record;
    })
  );
}
