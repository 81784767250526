import { useAppDispatch, useAppSelector, useContactLimitationWarning, WizardIntro } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  addContactsFromListsRequested,
  closeAddFromListPanel,
  selectContactsFromListToAdd,
  selectSelectedSending
} from 'app/pages/my-activities/sending-wizard';
import { RecipientFromList } from 'app/pages/my-activities/sendings';
import { AddContactFromListFooter, AddContactFromListSearch, AddContactFromListTable } from 'app/pages/my-activities/add-audience-wizard';

export const AddContactFromList = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { checkForContactNumberLimit } = useContactLimitationWarning();
  const selectedSending = useAppSelector(selectSelectedSending);

  const recipientsToAdd = useAppSelector<RecipientFromList[]>(selectContactsFromListToAdd);

  const totalNumberOfRecipients = selectedSending.numberOfRecipients + recipientsToAdd.length;

  const handleAddButtonClick = () => {
    if (recipientsToAdd?.length) {
      checkForContactNumberLimit({ totalNumberOfContacts: totalNumberOfRecipients, successCallback: addContactsFromListAndClosePanel });
    }
  };

  const addContactsFromListAndClosePanel = () => {
    dispatch(addContactsFromListsRequested());
    dispatch(closeAddFromListPanel());
  };

  return (
    <div className="wizard-content">
      <WizardIntro
        title={formatMessage({ id: 'add-contacts-from-lists-wizard.header' })}
        description={formatMessage({ id: 'add-contacts-from-lists-wizard.description' })}
      />
      <div className="wizard-content-main wizard-content-audience">
        <AddContactFromListSearch />
        <AddContactFromListTable />
      </div>
      <AddContactFromListFooter onAddButtonClick={handleAddButtonClick} />
    </div>
  );
};
