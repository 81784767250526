import { call, put, retry, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import {
  Comment,
  FilterItem,
  MessageData,
  safe,
  safelyHandleError,
  SAGA_RETRY_COUNT,
  SAGA_RETRY_DELAY_MS,
  selectSignedInUser,
  selectSignedInUserEmailAddress,
  setAlertData
} from 'app/common';
import { client } from 'app/common/graphql/graphql-gateway.client';
import { addCompany, Company, PageOfCompanies } from 'app/pages/my-audience/companies';
import {
  activityEmailAddressReceived,
  activityEmailAddressRequested,
  addActivityRequested,
  addBlacklistToContactRequested,
  addCommentToContactRequested,
  addCompanyAndCollaborationRequested,
  addCompanyCollaborationRequested,
  addedBlacklistToContact,
  addedCollaboration,
  addedCommentToContact,
  addedCompanyAndCollaboration,
  addedList,
  addedMediaOutletAndCollaboration,
  addedMediaResort,
  addedNewBlacklist,
  addedTopic,
  addListRequested,
  addListToContactRequested,
  addMediaOutletAndCollaborationRequested,
  addMediaOutletCollaborationRequested,
  addMediaResortAndAssignToContactRequested,
  addMediaResortToContactRequested,
  addNewBlacklistRequested,
  addTopicRequested,
  addTopicToContactRequested,
  allMediaResortsReceived,
  allTopicsReceived,
  closeAddCompanyWizard,
  closeAddMediaWizard,
  closeCreateCompanyWizard,
  closeCreateMediaWizard,
  ContactProfilePanelData,
  contactProfilePanelDataRequested,
  ContactProfilePicture,
  ContactUpdateModel,
  deletedMediaResorts,
  deletedTopics,
  deleteMediaResortsRequested,
  deleteTopicsRequested,
  getAllMediaResortsRequested,
  getAllTopicsRequested,
  getSelectedContactInfoRequested,
  Medium,
  pageOfMediaOutletsReceived,
  pageOfMediaOutletsRequested,
  profilePictureUpdated,
  removeBlacklistFromContactRequested,
  removeCollaborationRequested,
  removeCommentFromContactRequested,
  removedCollaboration,
  removedCommentFromContact,
  removeListFromContactRequested,
  removeMediaResortFromContactRequested,
  removeProfilePictureRequested,
  removeTopicFromContactRequested,
  selectActivityToLog,
  selectBlacklistToAdd,
  selectBlacklistToAddToContact,
  selectBlacklistToRemoveFromContact,
  selectCollaborationToAdd,
  selectCollaborationToRemove,
  selectCommentToAddToContact,
  selectCommentToRemoveFromContact,
  selectCompaniesCategoryFilter,
  selectCompaniesPageNumber,
  selectCompaniesPageSize,
  selectCompaniesSearchText,
  selectCompanyToAdd,
  firstPageOfSelectedContactActivitiesReceived,
  selectedContactInfoReceived,
  selectImageToUpload,
  selectListIdToRemoveFromContact,
  selectListToAdd,
  selectListToAddToContact,
  selectMediaOutletsCategoryFilter,
  selectMediaOutletsPageNumber,
  selectMediaOutletsPageSize,
  selectMediaOutletsSearchText,
  selectMediaOutletToAdd,
  selectMediaResortsToDelete,
  selectMediaResortToAdd,
  selectMediaResortToRemoveFromContact,
  selectSelectedCollaborationToUpdate,
  selectSelectedContactId,
  selectSelectedContactToUpdate,
  selectSelectedOwnContactDataToUpdate,
  selectTopicsToDelete,
  selectTopicToAdd,
  selectTopicToAddToContact,
  selectTopicToRemoveFromContact,
  updateCollaborationRequested,
  updateContactRequested,
  updatedCollaboration,
  updatedContact,
  updatedOwnContactData,
  updateOwnContactDataRequested,
  updateProfilePictureRequested,
  selectSelectedContact,
  updatePrimaryCollaborationRequested,
  primaryCollaborationUpdated,
  updatePrimaryOwnContactDataRequested,
  primaryOwnContactDataUpdated,
  fetchSelectedContactHasTeamsEnabledReceived,
  addListFromContactPanelRequested,
  pageOfCompaniesRequested,
  pageOfCompaniesReceived,
  selectedContactRecipientsReceived,
  selectedContactRecipientsRequested,
  removeBlacklistsRequested,
  selectBlacklistIdsToRemove,
  blacklistsRemoved,
  firstPageOfSelectedContactActivitiesRequested,
  nextPageOfSelectedContactActivitiesRequested,
  selectActivitiesPageNumber,
  selectActivitiesPageSize,
  nextPageOfSelectedContactActivitiesReceived,
  setTotalCountOfActivities,
  selectActivitiesFilterItems,
  selectedContactSendingActivitiesRequested,
  selectedContactSendingActivitiesReceived,
  fetchAllLoadedActivitiesReceived,
  selectLoadedActivitiesCount,
  addedListToContact,
  addedNewList,
  selectListsSearchText,
  nextPageOfListsRequested,
  nextPageOfListsReceived,
  selectListsPageNumber,
  selectListsPageSize,
  getListsByNameRequested,
  listsByNameReceived,
  selectedContactsListsReceived,
  selectedContactListsRequested,
  enableNavigationToNewlyCreatedList,
  updateContactPrimaryContactData,
  addedMediaResortToContact,
  selectMediaResortToAddToContact,
  setIsChoosingPhoneNumberDefaultState,
  setIsChoosingContactDataAsPrimary
} from 'app/pages/my-audience/contact-profile';
import {
  addBlacklistToContact,
  addCollaboration,
  addCommentToContact,
  addListToContact,
  addMediaResortToContact,
  addNewBlacklist,
  addTopicToContact,
  fetchActivityEmailAddress,
  fetchAllMediaResorts,
  fetchAllTopics,
  fetchCompaniesByNameAndCategoryAndNotContainedInContact,
  fetchContactHasTeamsEnabledByEmail,
  fetchContactProfilePanelData,
  fetchListsByContactId,
  fetchMediaOutletsByNameAndCategoryAndNotContainedInContact,
  fetchPagedSelectedContactActivities,
  fetchRecipientsByContactId,
  fetchSelectedContactProfile,
  fetchSendingsByContactId,
  logActivity,
  removeBlacklistFromContact,
  removeBlacklists,
  removeCollaboration,
  removeCommentFromContact,
  removeListFromContact,
  removeMediaResortFromContact,
  removeMediaResorts,
  removeProfilePicture,
  removeTopicFromContact,
  removeTopics,
  updateCollaboration,
  updateContact,
  updateOwnContactData,
  updateProfilePicture
} from 'app/pages/my-audience/contact-profile/services/contact-profile.service';
import {
  Activity,
  addedCollaborationToSearchResults,
  addMediaResort,
  addResortInTable,
  addTopic,
  Blacklist,
  Collaboration,
  Contact,
  MediaResort,
  MediumType,
  OwnContactData,
  PageOfActivities,
  removedCollaborationFromSearchResults,
  removedMediaResorts,
  removeResortsInTable,
  Topic,
  updatedContactsSearchResults,
  updatedProfilePictureSearchResults
} from 'app/pages/my-audience/contacts';
import { firstPageOfListsRequested, List, setFilterItems, setSearchText } from 'app/pages/my-audience/lists';
import { fetchListsByName, selectList, updateListContactForTheCorrespondingContact } from 'app/pages/my-audience/lists-profile';
import { addList } from 'app/pages/my-audience/lists/services/lists.service';
import { addMediaOutlet, MediaOutlet, PageOfMediaOutlets } from 'app/pages/my-audience/media-outlets';
import { setListAddedId } from 'app/pages/my-audience/wizard-create-list';
import { Recipient } from 'app/pages/my-activities/sendings';
import { updateSendingRecipientForTheCorrespondingContact } from 'app/pages/my-activities/sending-wizard';

function* fetchSelectedContactInfoFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const selectedContactInfo = yield call(fetchSelectedContactProfile, client, selectedContactId);
  yield put(selectedContactInfoReceived(selectedContactInfo));
}

export function* getSelectedContactInfoRequestedWatcher() {
  yield takeEvery(getSelectedContactInfoRequested.type, safe(fetchSelectedContactInfoFlow));
}

function* fetchSelectedContactActivitiesFlow() {
  const pageNumber: number = yield select(selectActivitiesPageNumber);
  const pageSize: number = yield select(selectActivitiesPageSize);
  const filterItems: FilterItem[] = yield select(selectActivitiesFilterItems);

  const selectedContactId: string = yield select(selectSelectedContactId);
  const pageOfActivities: PageOfActivities = yield call(
    fetchPagedSelectedContactActivities,
    client,
    selectedContactId,
    pageNumber,
    pageSize,
    filterItems ?? []
  );
  if (pageNumber === 1) {
    yield put(firstPageOfSelectedContactActivitiesReceived(pageOfActivities.activities));
  } else {
    yield put(nextPageOfSelectedContactActivitiesReceived(pageOfActivities.activities));
  }

  yield put(setTotalCountOfActivities(pageOfActivities.totalCount));
}

function* fetchAllLoadedActivitiesFlow() {
  const filterItems: FilterItem[] = yield select(selectActivitiesFilterItems);
  const numberOfActivitiesToFetch = yield select(selectLoadedActivitiesCount);

  const selectedContactId: string = yield select(selectSelectedContactId);
  const pageOfActivities: PageOfActivities = yield call(
    fetchPagedSelectedContactActivities,
    client,
    selectedContactId,
    1,
    numberOfActivitiesToFetch + 1,
    filterItems ?? []
  );

  yield put(fetchAllLoadedActivitiesReceived(pageOfActivities.activities));
}

export function* getSelectedContactActivitiesRequestedWatcher() {
  yield takeEvery(firstPageOfSelectedContactActivitiesRequested.type, safe(fetchSelectedContactActivitiesFlow));
  yield takeEvery(nextPageOfSelectedContactActivitiesRequested.type, safe(fetchSelectedContactActivitiesFlow));
}

function* fetchSelectedContactSendingActivitiesFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const sendingActivities = yield call(fetchSendingsByContactId, client, selectedContactId);

  yield put(selectedContactSendingActivitiesReceived(sendingActivities));
}

export function* selectedContactSendingActivitiesRequestedWatcher() {
  yield takeEvery(selectedContactSendingActivitiesRequested.type, safe(fetchSelectedContactSendingActivitiesFlow));
}

function* fetchAllTopicsFlow() {
  const allTopics = yield call(fetchAllTopics, client);
  yield put(allTopicsReceived(allTopics));
}

export function* getAllTopicsRequestedWatcher() {
  yield takeEvery(getAllTopicsRequested.type, safe(fetchAllTopicsFlow));
}

function* fetchAllMediaResortsFlow() {
  const allMediaResorts = yield call(fetchAllMediaResorts, client);
  yield put(allMediaResortsReceived(allMediaResorts));
}

export function* getAllMediaResortsRequestedWatcher() {
  yield takeEvery(getAllMediaResortsRequested.type, safe(fetchAllMediaResortsFlow));
}

function* addTopicToContactFlow() {
  const topicToAdd: Topic = yield select(selectTopicToAddToContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(addTopicToContact, client, selectedContactId, topicToAdd.id);
}

export function* addTopicToContactRequestedWatcher() {
  yield takeEvery(addTopicToContactRequested.type, safe(addTopicToContactFlow));
}

function* addMediaResortToContactFlow() {
  const mediaResortToAdd: MediaResort = yield select(selectMediaResortToAddToContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(addMediaResortToContact, client, selectedContactId, mediaResortToAdd.id);
}

export function* addMediaResortToContactRequestedWatcher() {
  yield takeEvery(addMediaResortToContactRequested.type, safe(addMediaResortToContactFlow));
}

function* addListToContactFlow() {
  const listToAdd: List = yield select(selectListToAddToContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(addListToContact, client, selectedContactId, listToAdd.id);
}

export function* addListToContactRequestedWatcher() {
  yield takeEvery(addListToContactRequested.type, safe(addListToContactFlow));
}

function* addListFlow() {
  const { name }: List = yield select(selectListToAdd);

  const list = yield call(addList, client, name);
  yield put(setListAddedId(list.id));
  yield put(addedList(list));
  yield put(selectList(list));

  yield put(setSearchText(list.name));
  yield put(setFilterItems([]));
  yield put(firstPageOfListsRequested());
  yield put(enableNavigationToNewlyCreatedList());
}

export function* addListRequestedWatcher() {
  yield takeEvery(addListRequested.type, safe(addListFlow));
}

function* addListFromContactPanelFlow() {
  const { name }: List = yield select(selectListToAdd);
  const selectedContactId = yield select(selectSelectedContactId);

  const list = yield call(addList, client, name);
  yield call(addListToContact, client, selectedContactId, list.id);

  yield put(setListAddedId(list.id));
  yield put(addedListToContact(list));
  yield put(addedNewList(list));
}

export function* addListFromContactPanelRequestedWatcher() {
  yield takeEvery(addListFromContactPanelRequested.type, safe(addListFromContactPanelFlow));
}

function* addBlacklistToContactFlow() {
  const blacklistToAdd: Blacklist = yield select(selectBlacklistToAddToContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(addBlacklistToContact, client, selectedContactId, blacklistToAdd);
}

export function* addBlacklistToContactRequestedWatcher() {
  yield takeEvery(addBlacklistToContactRequested.type, safe(addBlacklistToContactFlow));
}

function* addNewBlacklistFlow() {
  const blacklistToAdd: string = yield select(selectBlacklistToAdd);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const blacklist = yield call(addNewBlacklist, client, selectedContactId, blacklistToAdd);
  yield put(addedNewBlacklist(blacklist));
  yield put(addedBlacklistToContact(blacklist));
}

export function* addNewBlacklistRequestedWatcher() {
  yield takeEvery(addNewBlacklistRequested.type, safe(addNewBlacklistFlow));
}

function* removeTopicFromContactFlow() {
  const topicToRemove: Topic = yield select(selectTopicToRemoveFromContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(removeTopicFromContact, client, selectedContactId, topicToRemove.id);
}

export function* removeTopicFromContactRequestedWatcher() {
  yield takeEvery(removeTopicFromContactRequested.type, safe(removeTopicFromContactFlow));
}

function* removeMediaResortFromContactFlow() {
  const mediaResortToRemove: MediaResort = yield select(selectMediaResortToRemoveFromContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(removeMediaResortFromContact, client, selectedContactId, mediaResortToRemove.id);
}

export function* removeMediaResortFromContactRequestedWatcher() {
  yield takeEvery(removeMediaResortFromContactRequested.type, safe(removeMediaResortFromContactFlow));
}

function* removeListFromContactFlow() {
  const listIdToRemove: string = yield select(selectListIdToRemoveFromContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(removeListFromContact, client, selectedContactId, listIdToRemove);
}

export function* removeListFromContactRequestedWatcher() {
  yield takeEvery(removeListFromContactRequested.type, safe(removeListFromContactFlow));
}

function* removeBlacklistFromContactFlow() {
  const blacklistToRemove: Blacklist = yield select(selectBlacklistToRemoveFromContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(removeBlacklistFromContact, client, selectedContactId, blacklistToRemove);
}

export function* removeBlacklistFromContactRequestedWatcher() {
  yield takeEvery(removeBlacklistFromContactRequested.type, safe(removeBlacklistFromContactFlow));
}

function* updateCollaborationFlow() {
  const contactId: string = yield select(selectSelectedContactId);
  const collaborationToUpdate: Collaboration = yield select(selectSelectedCollaborationToUpdate);

  const contact: Contact = yield call(updateCollaboration, client, contactId, collaborationToUpdate);
  yield put(updatedCollaboration(contact));
  yield put(updateSendingRecipientForTheCorrespondingContact(contact));
  yield put(updateListContactForTheCorrespondingContact(contact));
  yield put(updatedContactsSearchResults(contact));
}

export function* updateCollaborationRequestedWatcher() {
  yield takeEvery(updateCollaborationRequested.type, safelyHandleError({ performAction: updateCollaborationFlow, onFinally: finishEditingContactDataFlow }));
}

function* updatePrimaryCollaborationFlow() {
  const contactId: string = yield select(selectSelectedContactId);
  const collaborationToUpdate: Collaboration = yield select(selectSelectedCollaborationToUpdate);

  const contact: Contact = yield call(updateCollaboration, client, contactId, collaborationToUpdate);
  yield put(primaryCollaborationUpdated(contact));
}

export function* updatePrimaryCollaborationRequestedWatcher() {
  yield takeEvery(
    updatePrimaryCollaborationRequested.type,
    safelyHandleError({ performAction: updatePrimaryCollaborationFlow, onFinally: finishEditingPrimaryDataFlow })
  );
}

function* updateOwnContactDataFlow() {
  const contactId: string = yield select(selectSelectedContactId);
  const ownContactData: OwnContactData = yield select(selectSelectedOwnContactDataToUpdate);

  const contact: Contact = yield call(updateOwnContactData, client, contactId, ownContactData);
  yield put(updatedOwnContactData(contact));
  yield put(updateSendingRecipientForTheCorrespondingContact(contact));
  yield put(updateListContactForTheCorrespondingContact(contact));
  yield put(updatedContactsSearchResults(contact));
}

export function* updateOwnContactDataRequestedWatcher() {
  yield takeEvery(updateOwnContactDataRequested.type, safelyHandleError({ performAction: updateOwnContactDataFlow, onFinally: finishEditingContactDataFlow }));
}

function* updatePrimaryOwnContactDataFlow() {
  const contactId: string = yield select(selectSelectedContactId);
  const ownContactData: OwnContactData = yield select(selectSelectedOwnContactDataToUpdate);

  const contact: Contact = yield call(updateOwnContactData, client, contactId, ownContactData);
  yield put(primaryOwnContactDataUpdated(contact));
}

export function* updatePrimaryOwnContactDataRequestedWatcher() {
  yield takeEvery(
    updatePrimaryOwnContactDataRequested.type,
    safelyHandleError({ performAction: updatePrimaryOwnContactDataFlow, onFinally: finishEditingPrimaryDataFlow })
  );
}

function* finishEditingPrimaryDataFlow() {
  yield put(setIsChoosingContactDataAsPrimary(false));
}

function* finishEditingContactDataFlow() {
  yield put(setIsChoosingPhoneNumberDefaultState(false));
}

function* updateContactFlow() {
  const id: string = yield select(selectSelectedContactId);
  const contactToUpdate: ContactUpdateModel = yield select(selectSelectedContactToUpdate);

  const contactUpdateModel = yield call(updateContact, client, id, contactToUpdate);
  yield put(updatedContact(contactUpdateModel));
}

export function* updateContactRequestedWatcher() {
  yield takeEvery(updateContactRequested.type, safe(updateContactFlow));
}

function* updateProfilePictureFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const imageToUpload: ContactProfilePicture = yield select(selectImageToUpload);

  const imageUrl = yield call(updateProfilePicture, client, selectedContactId, imageToUpload);
  yield put(profilePictureUpdated(imageUrl));
  yield put(updatedProfilePictureSearchResults({ contactId: selectedContactId, imageUrl: imageUrl }));
}

export function* updateProfilePictureRequestedWatcher() {
  yield takeEvery(updateProfilePictureRequested.type, safe(updateProfilePictureFlow));
}

function* removeProfilePictureFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);

  yield call(removeProfilePicture, client, selectedContactId);
  yield put(profilePictureUpdated(''));
  yield put(updatedProfilePictureSearchResults({ contactId: selectedContactId, imageUrl: '' }));
}

export function* removeProfilePictureRequestedWatcher() {
  yield takeEvery(removeProfilePictureRequested.type, safe(removeProfilePictureFlow));
}

function* addCollaborationFlow() {
  const collaborationToAdd: Collaboration = yield select(selectCollaborationToAdd);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const collaboration = yield call(addCollaboration, client, selectedContactId, collaborationToAdd);
  yield put(addedCollaboration(collaboration));
  yield put(addedCollaborationToSearchResults({ collaboration, contactId: selectedContactId }));
  yield put(closeAddMediaWizard());
  yield put(closeAddCompanyWizard());

  const selectedContactInfo = yield call(fetchSelectedContactProfile, client, selectedContactId);
  yield put(updateContactPrimaryContactData(selectedContactInfo));
}

export function* addCollaborationRequestedWatcher() {
  yield takeEvery(addCompanyCollaborationRequested.type, safe(addCollaborationFlow));
  yield takeEvery(addMediaOutletCollaborationRequested.type, safe(addCollaborationFlow));
}

function* removeCollaborationFlow() {
  const collaborationToRemove: Collaboration = yield select(selectCollaborationToRemove);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const collaboration = yield call(removeCollaboration, client, selectedContactId, collaborationToRemove.id);

  yield put(removedCollaboration(collaboration));
  yield put(removedCollaborationFromSearchResults({ collaborationId: collaborationToRemove.id, contactId: selectedContactId }));

  const selectedContactInfo = yield call(fetchSelectedContactProfile, client, selectedContactId);
  yield put(updateContactPrimaryContactData(selectedContactInfo));
}

export function* removeCollaborationRequestedWatcher() {
  yield takeEvery(removeCollaborationRequested.type, safe(removeCollaborationFlow));
}

function* addCommentToContactFlow() {
  const commentToAdd: Comment = yield select(selectCommentToAddToContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const comment = yield call(addCommentToContact, client, selectedContactId, commentToAdd);
  yield put(addedCommentToContact(comment));
}

export function* addCommentToContactRequestedWatcher() {
  yield takeEvery(addCommentToContactRequested.type, safe(addCommentToContactFlow));
}

function* removeCommentFromContactFlow() {
  const commentToRemove: Comment = yield select(selectCommentToRemoveFromContact);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const comment = yield call(removeCommentFromContact, client, selectedContactId, commentToRemove.id);
  yield put(removedCommentFromContact(comment));
}

export function* removeCommentFromContactRequestedWatcher() {
  yield takeEvery(removeCommentFromContactRequested.type, safe(removeCommentFromContactFlow));
}

function* addActivityFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const activityToAdd: Activity = yield select(selectActivityToLog);
  const signedInUser = yield select(selectSignedInUser);

  yield call(logActivity, client, selectedContactId, activityToAdd, signedInUser.id);
  yield call(fetchAllLoadedActivitiesFlow);

  yield put(setAlertData(new MessageData('alert-activity-created')));
}

export function* addActivityRequestedWatcher() {
  yield takeLatest(addActivityRequested.type, safe(addActivityFlow));
}

function* addMediaOutletAndCollaborationFlow() {
  const mediaOutletToAdd: MediaOutlet = yield select(selectMediaOutletToAdd);
  const collaborationToAdd: Collaboration = yield select(selectCollaborationToAdd);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const mediaOutlet: MediaOutlet = yield call(addMediaOutlet, client, mediaOutletToAdd);
  collaborationToAdd.medium = new Medium(mediaOutlet.id, mediaOutlet.name, MediumType.MediaOutlet);
  const collaboration = yield retry(SAGA_RETRY_COUNT, SAGA_RETRY_DELAY_MS, addCollaboration, client, selectedContactId, collaborationToAdd);
  yield put(addedMediaOutletAndCollaboration(collaboration));
  yield put(addedCollaborationToSearchResults({ collaboration, contactId: selectedContactId }));
  yield put(closeAddMediaWizard());
  yield put(closeCreateMediaWizard());

  const selectedContactInfo = yield call(fetchSelectedContactProfile, client, selectedContactId);
  yield put(updateContactPrimaryContactData(selectedContactInfo));
}

export function* addMediaOutletAndCollaborationRequestedWatcher() {
  yield takeEvery(addMediaOutletAndCollaborationRequested.type, safe(addMediaOutletAndCollaborationFlow));
}

function* addCompanyAndCollaborationFlow() {
  const companyToAdd: Company = yield select(selectCompanyToAdd);
  const collaborationToAdd: Collaboration = yield select(selectCollaborationToAdd);
  const selectedContactId: string = yield select(selectSelectedContactId);

  const company = yield call(addCompany, client, companyToAdd);
  collaborationToAdd.medium = new Medium(company.id, company.name, MediumType.Company);
  const collaboration = yield retry(SAGA_RETRY_COUNT, SAGA_RETRY_DELAY_MS, addCollaboration, client, selectedContactId, collaborationToAdd);
  yield put(addedCompanyAndCollaboration(collaboration));
  yield put(addedCollaborationToSearchResults({ collaboration, contactId: selectedContactId }));
  yield put(closeAddCompanyWizard());
  yield put(closeCreateCompanyWizard());

  const selectedContactInfo = yield call(fetchSelectedContactProfile, client, selectedContactId);
  yield put(updateContactPrimaryContactData(selectedContactInfo));
}

export function* addCompanyAndCollaborationRequestedWatcher() {
  yield takeEvery(addCompanyAndCollaborationRequested.type, safe(addCompanyAndCollaborationFlow));
}

function* fetchContactProfilePanelDataFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const contactProfilePanelData: ContactProfilePanelData = yield call(fetchContactProfilePanelData, client, selectedContactId);

  yield put(selectedContactInfoReceived(contactProfilePanelData.contact));
}

export function* fetchContactProfilePanelDataFlowWatcher() {
  yield takeEvery(contactProfilePanelDataRequested.type, safe(fetchContactProfilePanelDataFlow));
}

export function* fetchActivityEmailAddressFlow() {
  const activityEmailAddress = yield call(fetchActivityEmailAddress, client);

  yield put(activityEmailAddressReceived(activityEmailAddress));
}

export function* fetchActivityEmailAddressRequestedWatcher() {
  yield takeEvery(activityEmailAddressRequested, safe(fetchActivityEmailAddressFlow));
}

function* fetchMediaOutletsFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const pageNumber: number = yield select(selectMediaOutletsPageNumber);
  const pageSize: number = yield select(selectMediaOutletsPageSize);
  const searchText: string = yield select(selectMediaOutletsSearchText);
  const category: string = yield select(selectMediaOutletsCategoryFilter);

  const pageOfMediaOutlets: PageOfMediaOutlets = yield call(
    fetchMediaOutletsByNameAndCategoryAndNotContainedInContact,
    client,
    pageNumber,
    pageSize,
    searchText,
    category,
    selectedContactId
  );

  yield put(pageOfMediaOutletsReceived({ mediaOutlets: pageOfMediaOutlets.mediaOutlets, totalCountOfMediaOutlets: pageOfMediaOutlets.totalCount }));
}

export function* getMediaOutletsRequestedWatcher() {
  yield takeEvery(pageOfMediaOutletsRequested.type, safe(fetchMediaOutletsFlow));
}

function* fetchCompaniesFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const pageNumber: number = yield select(selectCompaniesPageNumber);
  const pageSize: number = yield select(selectCompaniesPageSize);
  const searchText: string = yield select(selectCompaniesSearchText);
  const category: string = yield select(selectCompaniesCategoryFilter);

  const pageOfCompanies: PageOfCompanies = yield call(
    fetchCompaniesByNameAndCategoryAndNotContainedInContact,
    client,
    pageNumber,
    pageSize,
    searchText,
    category,
    selectedContactId
  );

  yield put(pageOfCompaniesReceived({ companies: pageOfCompanies.companies, totalCountOfCompanies: pageOfCompanies.totalCount }));
}

export function* getCompaniesRequestedWatcher() {
  yield takeEvery(pageOfCompaniesRequested.type, safe(fetchCompaniesFlow));
}

export function* addMediaResortAndAssignToContactFlow() {
  const selectedContact: Contact = yield select(selectSelectedContact);
  const mediaResort: MediaResort = yield select(selectMediaResortToAdd);

  const createdMediaResort: MediaResort = yield call(addMediaResort, client, mediaResort);
  yield put(addedMediaResort(createdMediaResort));
  yield put(addMediaResortToContactRequested(createdMediaResort));
  yield put(addedMediaResortToContact(createdMediaResort));
  yield put(addResortInTable({ contactIds: [selectedContact.id], resorts: [createdMediaResort] }));
}

export function* addMediaResortAndAssignToContactWatcher() {
  yield takeEvery(addMediaResortAndAssignToContactRequested.type, safe(addMediaResortAndAssignToContactFlow));
}

export function* addTopicFlow() {
  const topic: Topic = yield select(selectTopicToAdd);

  const createdTopic: Topic = yield call(addTopic, client, topic);
  yield put(addedTopic(createdTopic));
}

export function* addTopicRequestedWatcher() {
  yield takeEvery(addTopicRequested.type, safe(addTopicFlow));
}

function* deleteTopicsFlow() {
  const topicsToRemove: Topic[] = yield select(selectTopicsToDelete);

  const removedTopicsIds = yield call(
    removeTopics,
    client,
    topicsToRemove.map((t) => t.id)
  );
  yield put(deletedTopics(removedTopicsIds));
}

export function* fetchSelectedContactHasTeamsEnabledFlow() {
  const contact: Contact = yield select(selectSelectedContact);
  const contactEmailAddress: string = contact.primaryEmailAddress();
  if (!contactEmailAddress) return;

  const signedInUserEmailAddress = yield select(selectSignedInUserEmailAddress);
  try {
    const contactHasTeamsEnabled = yield call(fetchContactHasTeamsEnabledByEmail, signedInUserEmailAddress, contactEmailAddress);
    yield put(fetchSelectedContactHasTeamsEnabledReceived(contactHasTeamsEnabled));
  } catch (error) {
    yield put(fetchSelectedContactHasTeamsEnabledReceived(false));

    console.error(error);
    Sentry.setExtra('Path', fetchSelectedContactHasTeamsEnabledFlow.name);
    Sentry.captureException(error);
  }
}

export function* removeTopicsRequestedWatcher() {
  yield takeEvery(deleteTopicsRequested.type, safe(deleteTopicsFlow));
}

function* deleteMediaResortsFlow() {
  const mediaResortsToRemove: MediaResort[] = yield select(selectMediaResortsToDelete);

  const removedMediaResortsIds = yield call(
    removeMediaResorts,
    client,
    mediaResortsToRemove.map((t) => t.id)
  );

  yield put(deletedMediaResorts(removedMediaResortsIds));
  yield put(removedMediaResorts(removedMediaResortsIds));
  yield put(removeResortsInTable(removedMediaResortsIds));
}

export function* removeMediaResortsRequestedWatcher() {
  yield takeEvery(deleteMediaResortsRequested.type, safe(deleteMediaResortsFlow));
}

export function* selectedContactInfoReceivedWatcher() {
  yield takeLatest(selectedContactInfoReceived.type, fetchSelectedContactHasTeamsEnabledFlow);
}

function* fetchContactRecipientsFlow() {
  const selectedContactId: string = yield select(selectSelectedContactId);
  const recipients: Recipient[] = yield call(fetchRecipientsByContactId, client, selectedContactId);

  yield put(selectedContactRecipientsReceived(recipients));
}

export function* fetchContactRecipientsRequestedWatcher() {
  yield takeEvery(selectedContactRecipientsRequested.type, safe(fetchContactRecipientsFlow));
}

function* removeBlacklistsFlow() {
  const blacklistsIdsToRemove: string[] = yield select(selectBlacklistIdsToRemove);

  const removedIds = yield call(removeBlacklists, client, blacklistsIdsToRemove);
  yield put(blacklistsRemoved(removedIds));
}

export function* removeBlacklistsRequestedWatcher() {
  yield takeEvery(removeBlacklistsRequested.type, safe(removeBlacklistsFlow));
}

function* fetchListsByNameFlow() {
  const listsSearchText: string = yield select(selectListsSearchText);
  const pageNumber = yield select(selectListsPageNumber);
  const pageSize = yield select(selectListsPageSize);

  const skip = (pageNumber - 1) * pageSize;
  const lists: List[] = yield call(fetchListsByName, client, listsSearchText, skip, pageSize);

  yield put(listsByNameReceived(lists));
}

export function* getContactProfileListsByNameRequestedWatcher() {
  yield takeLatest(getListsByNameRequested.type, safe(fetchListsByNameFlow));
}

function* fetchNextPageOfListsFlow() {
  const listsSearchText: string = yield select(selectListsSearchText);
  const pageNumber = yield select(selectListsPageNumber);
  const pageSize = yield select(selectListsPageSize);

  const skip = (pageNumber - 1) * pageSize;
  const lists: List[] = yield call(fetchListsByName, client, listsSearchText, skip, pageSize);

  yield put(nextPageOfListsReceived(lists));
}

export function* getContactProfileListsNextPageRequestedWatcher() {
  yield takeLatest(nextPageOfListsRequested.type, safe(fetchNextPageOfListsFlow));
}

function* fetchContactsListsFlow() {
  const contactId = yield select(selectSelectedContactId);
  const lists = yield call(fetchListsByContactId, client, contactId);

  yield put(selectedContactsListsReceived(lists));
}

export function* getContactsListsRequestedWatcher() {
  yield takeEvery(selectedContactListsRequested.type, safe(fetchContactsListsFlow));
}
