import { cloneDeep } from 'lodash';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterItem, QueryParams, SearchSuggestionCategory, TableHeader, Tag, TagProjection } from 'app/common';
import {
  Collaboration,
  Contact,
  ContactSearchResultTableData,
  createContactSearchResultTableData,
  ISortingInput,
  MediaResort,
  Topic
} from 'app/pages/my-audience/contacts';
import { RootState } from 'app/redux/store';
import { List } from 'app/pages/my-audience/lists';

export interface ContactsState {
  contacts: Contact[];
  previousContacts: Contact[];
  searchText: string;
  isLoadingSearchResults: boolean;
  pageNumber: number;
  pageSize: number;
  totalCountOfContacts: number;
  searchSuggestionsText: string;
  contactSuggestions: SearchSuggestionCategory[];
  isLoadingSearchSuggestions: boolean;
  filterItems: FilterItem[];
  contactPreviousValue: Contact;
  contactsToAdd: Contact[];
  selectedContacts: Contact[];
  searchResultsTableHeader: TableHeader[];
  exportHeader: TableHeader[];
  contactToRemove: Contact;
  isSearchAnimationActive: boolean;
  isSavedSearchesPanelOpen: boolean;
  isFiltersPanelOpen: boolean;
  contactNameSuggestions: string[];
  isLoadingContactNameSuggestions: boolean;
  roleSuggestions: string[];
  isLoadingRoleSuggestions: boolean;
  mediumNameSuggestions: string[];
  isLoadingMediumNameSuggestions: boolean;
  resortSuggestions: string[];
  isLoadingResortSuggestions: boolean;
  languageSuggestions: string[];
  isLoadingLanguageSuggestions: boolean;
  isLoadingMediaColumnSuggestions: boolean;
  tagNameSuggestions: string[];
  isLoadingTagSuggestions: boolean;
  countrySuggestions: string[];
  isLoadingCountrySuggestions: boolean;
  filterSuggestionsSearchText: string;
  filterSuggestionsPageSize: number;
  sortingInput: ISortingInput;
  contactsToRemoveIds: string[];
  isUpdateDialogVisible: boolean;
  listsIdsForBulkUpdate: string[];
  mediaResortsIdsForBulkUpdate: string[];
  topicsIdsForBulkUpdate: string[];
  listsToAddToContact: List[];
  mediaResortsToAddToContact: MediaResort[];
  mediaResortToAdd: MediaResort;
  topicToAdd: Topic;
  topicsToAddToContact: Topic[];
  allTags: Tag[];
  filteredTags: Tag[];
  tagNameToCreate: string;
  tagsToDelete: Tag[];
  tagToAddToContacts: TagProjection;
  tagToRemoveFromContacts: TagProjection;
  isLoadingTagNameSuggestions: boolean;
  listNameToAdd: string;
  contactsIdsToAddTag: string[];
}

const initialState: ContactsState = {
  pageNumber: 1,
  pageSize: 30,
  totalCountOfContacts: 0,
  contactSuggestions: [],
  contacts: [],
  previousContacts: [],
  searchText: '',
  searchSuggestionsText: '',
  filterItems: [],
  contactToRemove: {} as Contact,
  isLoadingSearchResults: false,
  contactPreviousValue: {} as Contact,
  selectedContacts: [],
  contactsToAdd: [],
  isLoadingSearchSuggestions: false,
  searchResultsTableHeader: [],
  exportHeader: [],
  isSearchAnimationActive: false,
  isSavedSearchesPanelOpen: false,
  isFiltersPanelOpen: false,
  contactNameSuggestions: [],
  isLoadingContactNameSuggestions: false,
  roleSuggestions: [],
  isLoadingRoleSuggestions: false,
  mediumNameSuggestions: [],
  isLoadingMediumNameSuggestions: false,
  resortSuggestions: [],
  isLoadingResortSuggestions: false,
  languageSuggestions: [],
  isLoadingLanguageSuggestions: false,
  isLoadingMediaColumnSuggestions: false,
  tagNameSuggestions: [],
  countrySuggestions: [],
  isLoadingCountrySuggestions: false,
  isLoadingTagSuggestions: false,
  filterSuggestionsSearchText: '',
  filterSuggestionsPageSize: 10,
  sortingInput: { sortOption: 'CONTACT_NAME', sortOrder: 'ASC' },
  contactsToRemoveIds: [],
  isUpdateDialogVisible: false,
  listsIdsForBulkUpdate: [],
  mediaResortsIdsForBulkUpdate: [],
  topicsIdsForBulkUpdate: [],
  listsToAddToContact: [],
  mediaResortsToAddToContact: [],
  mediaResortToAdd: {} as MediaResort,
  topicToAdd: {} as Topic,
  topicsToAddToContact: [],
  allTags: [],
  filteredTags: [],
  tagNameToCreate: '',
  tagsToDelete: [],
  tagToAddToContacts: {} as TagProjection,
  tagToRemoveFromContacts: {} as TagProjection,
  isLoadingTagNameSuggestions: false,
  listNameToAdd: '',
  contactsIdsToAddTag: []
};

export type ContactsToRemoveIdsSelector = (state: RootState) => string[];
export const selectContactsToRemoveIds: ContactsToRemoveIdsSelector = createSelector(
  [(state: RootState) => state.contacts.contactsToRemoveIds],
  (contactsToRemoveIds: string[]) => contactsToRemoveIds
);

export type SelectedContactsIdsSelector = (state: RootState) => string[];
export const selectSelectedContactsIds: SelectedContactsIdsSelector = createSelector(
  (state: RootState) => state.contacts.selectedContacts.map((c) => c.id),
  (selectedContactsIds: string[]) => selectedContactsIds
);

export type SortingInputSelector = (state: RootState) => ISortingInput;
export const selectSortingInput: SortingInputSelector = createSelector(
  [(state: RootState) => state.contacts.sortingInput],
  (sortingInput: ISortingInput) => sortingInput
);

export type PageNumberSelector = (state: RootState) => number;
export const selectPageNumber: PageNumberSelector = createSelector([(state: RootState) => state.contacts.pageNumber], (pageNumber: number) => pageNumber);

export type PageSizeSelector = (state: RootState) => number;
export const selectPageSize: PageSizeSelector = createSelector([(state: RootState) => state.contacts.pageSize], (pageSize: number) => pageSize);

export type TotalCountOfContactsSelector = (state: RootState) => number;
export const selectTotalCountOfContacts: TotalCountOfContactsSelector = createSelector(
  [(state: RootState) => state.contacts.totalCountOfContacts],
  (totalCountOfContacts: number) => totalCountOfContacts
);

export type ContactSuggestionsSelector = (state: RootState) => SearchSuggestionCategory[];
export const selectContactSuggestions: ContactSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.contactSuggestions],
  (contactSuggestions: SearchSuggestionCategory[]) => contactSuggestions
);

export type IsLoadingSearchSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchSuggestions: IsLoadingSearchSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingSearchSuggestions],
  (isLoadingSearchSuggestions: boolean) => isLoadingSearchSuggestions
);

export type ContactsSelector = (state: RootState) => Contact[];
export const selectContacts: ContactsSelector = createSelector([(state: RootState) => state.contacts.contacts], (contacts: Contact[]) => contacts);

export type FilterItemsSelector = (state: RootState) => FilterItem[];
export const selectFilterItems: FilterItemsSelector = createSelector(
  [(state: RootState) => state.contacts.filterItems],
  (filterItems: FilterItem[]) => filterItems
);

export type SearchTextSelector = (state: RootState) => string;
export const selectSearchText: SearchTextSelector = createSelector([(state: RootState) => state.contacts.searchText], (searchText: string) => searchText);

export type ContactSearchSuggestionsTextSelector = (state: RootState) => string;
export const selectContactsSearchSuggestionsText: ContactSearchSuggestionsTextSelector = createSelector(
  [(state: RootState) => state.contacts.searchSuggestionsText],
  (searchSuggestionsText: string) => searchSuggestionsText
);

export type ContactToRemoveSelector = (state: RootState) => Contact;
export const selectContactToRemove: ContactToRemoveSelector = createSelector(
  [(state: RootState) => state.contacts.contactToRemove],
  (contactToRemove: Contact) => contactToRemove
);

export type IsLoadingSearchResultsSelector = (state: RootState) => boolean;
export const selectIsLoadingSearchResults: IsLoadingSearchResultsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingSearchResults],
  (isLoadingSearchResults: boolean) => isLoadingSearchResults
);

export type ContactsToAddSelector = (state: RootState) => Contact[];
export const selectContactsToAdd: ContactsToAddSelector = createSelector(
  [(state: RootState) => state.contacts.contactsToAdd],
  (contactsToAdd: Contact[]) => contactsToAdd
);

export type SearchResultTableRowsSelector = (state: RootState) => ContactSearchResultTableData[];
export const selectSearchResultTableRows: SearchResultTableRowsSelector = createSelector(
  [(state: RootState) => state.contacts.contacts],
  (contacts: Contact[]) => contacts.map((contact) => createContactSearchResultTableData(contact))
);

export type SearchResultsTableHeaderSelector = (state: RootState) => TableHeader[];
export const selectSearchResultTableHeader: SearchResultsTableHeaderSelector = createSelector(
  [(state: RootState) => state.contacts.searchResultsTableHeader],
  (searchResultsTableHeader: TableHeader[]) => searchResultsTableHeader
);

export type ExportHeaderSelector = (state: RootState) => TableHeader[];
export const selectExportHeader: ExportHeaderSelector = createSelector(
  [(state: RootState) => state.contacts.exportHeader],
  (exportHeader: TableHeader[]) => exportHeader
);

export type IsSearchAnimationActiveSelector = (state: RootState) => boolean;
export const selectIsSearchAnimationActive: IsSearchAnimationActiveSelector = createSelector(
  [(state: RootState) => state.contacts.isSearchAnimationActive],
  (isSearchAnimationActive: boolean) => isSearchAnimationActive
);

export type IsSavedSearchesPanelOpenSelector = (state: RootState) => boolean;
export const selectIsSavedSearchesPanelOpen: IsSavedSearchesPanelOpenSelector = createSelector(
  [(state: RootState) => state.contacts.isSavedSearchesPanelOpen],
  (isSavedSearchesPanelOpen: boolean) => isSavedSearchesPanelOpen
);

export type CountOfLoadedContactsSelector = (state: RootState) => number;
export const selectCountOfLoadedContacts: CountOfLoadedContactsSelector = createSelector(
  [(state: RootState) => state.contacts.contacts],
  (contacts: Contact[]) => contacts.length
);

export type IsFilterPanelOpenSelector = (state: RootState) => boolean;
export const selectIsFilterPanelOpen: IsFilterPanelOpenSelector = createSelector(
  [(state: RootState) => state.contacts.isFiltersPanelOpen],
  (isFiltersPanelOpen: boolean) => isFiltersPanelOpen
);

export type ContactNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectContactNameSuggestions: ContactNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.contactNameSuggestions],
  (contactNameSuggestions: string[]) => contactNameSuggestions
);

export type IsLoadingContactNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingContactNameSuggestions: IsLoadingContactNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingContactNameSuggestions],
  (isLoadingContactNameSuggestions: boolean) => isLoadingContactNameSuggestions
);

export type RoleFilterSuggestionsSelector = (state: RootState) => string[];
export const selectRoleSuggestions: RoleFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.roleSuggestions],
  (roleSuggestions: string[]) => roleSuggestions
);

export type IsLoadingRoleSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingRoleSuggestions: IsLoadingRoleSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingRoleSuggestions],
  (isLoadingRoleSuggestions: boolean) => isLoadingRoleSuggestions
);

export type MediumNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectMediumNameSuggestions: MediumNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.mediumNameSuggestions],
  (mediumNameSuggestions: string[]) => mediumNameSuggestions
);

export type IsLoadingMediumNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingMediumNameSuggestions: IsLoadingMediumNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingMediumNameSuggestions],
  (isLoadingMediumNameSuggestions: boolean) => isLoadingMediumNameSuggestions
);

export type ResortFilterSuggestionsSelector = (state: RootState) => string[];
export const selectResortSuggestions: ResortFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.resortSuggestions],
  (resortSuggestions: string[]) => resortSuggestions
);

export type IsLoadingResortSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingResortSuggestions: IsLoadingResortSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingResortSuggestions],
  (isLoadingResortSuggestions: boolean) => isLoadingResortSuggestions
);

export type LanguageFilterSuggestionsSelector = (state: RootState) => string[];
export const selectLanguageSuggestions: LanguageFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.languageSuggestions],
  (languageSuggestions: string[]) => languageSuggestions
);

export type IsLoadingLanguageSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingLanguageSuggestions: IsLoadingLanguageSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingLanguageSuggestions],
  (isLoadingLanguageSuggestions: boolean) => isLoadingLanguageSuggestions
);

export type IsLoadingMediaColumnSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingMediaColumnSuggestions: IsLoadingMediaColumnSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingMediaColumnSuggestions],
  (isLoadingMediaColumnSuggestions: boolean) => isLoadingMediaColumnSuggestions
);

export type TagNameFilterSuggestionsSelector = (state: RootState) => string[];
export const selectTagNameSuggestions: TagNameFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.tagNameSuggestions],
  (tagNameSuggestions: string[]) => tagNameSuggestions
);

export type IsLoadingTagNameSuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingTagNameSuggestions: IsLoadingTagNameSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingTagNameSuggestions],
  (isLoadingTagNameSuggestions: boolean) => isLoadingTagNameSuggestions
);

export type CountryFilterSuggestionsSelector = (state: RootState) => string[];
export const selectCountrySuggestions: CountryFilterSuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.countrySuggestions],
  (countrySuggestions: string[]) => countrySuggestions
);

export type IsLoadingCountrySuggestionsSelector = (state: RootState) => boolean;
export const selectIsLoadingCountrySuggestions: IsLoadingCountrySuggestionsSelector = createSelector(
  [(state: RootState) => state.contacts.isLoadingCountrySuggestions],
  (isLoadingCountrySuggestions: boolean) => isLoadingCountrySuggestions
);

export type MediaResortToAddSelector = (state: RootState) => MediaResort;
export const selectMediaResortToAdd: MediaResortToAddSelector = createSelector(
  (state: RootState) => state.contacts.mediaResortToAdd,
  (mediaResortToAdd: MediaResort) => mediaResortToAdd
);

export type TopicToAddSelector = (state: RootState) => Topic;
export const selectTopicToAdd: TopicToAddSelector = createSelector(
  (state: RootState) => state.contacts.topicToAdd,
  (topicToAdd: MediaResort) => topicToAdd
);

export type ListToAddSelector = (state: RootState) => string;
export const selectListNameToAdd: ListToAddSelector = createSelector(
  (state: RootState) => state.contacts.listNameToAdd,
  (listNameToAdd: string) => listNameToAdd
);

export type FilterSuggestionsSearchTextSelector = (state: RootState) => string;
export const selectFilterSuggestionsSearchText: FilterSuggestionsSearchTextSelector = createSelector(
  [(state: RootState) => state.contacts.filterSuggestionsSearchText],
  (filterSuggestionsSearchText: string) => filterSuggestionsSearchText
);

export type FilterSuggestionsPageSizeSelector = (state: RootState) => number;
export const selectFilterSuggestionsPageSize: FilterSuggestionsPageSizeSelector = createSelector(
  [(state: RootState) => state.contacts.filterSuggestionsPageSize],
  (filterSuggestionsPageSize: number) => filterSuggestionsPageSize
);

export type IsUpdateDialogVisibleSelector = (state: RootState) => boolean;
export const selectIsUpdateDialogVisible: IsUpdateDialogVisibleSelector = createSelector(
  [(state: RootState) => state.contacts.isUpdateDialogVisible],
  (isUpdateDialogVisible: boolean) => isUpdateDialogVisible
);

export type ListsIdsForBulkUpdateSelector = (state: RootState) => string[];
export const selectListsIdsForBulkUpdate: ListsIdsForBulkUpdateSelector = createSelector(
  [(state: RootState) => state.contacts.listsIdsForBulkUpdate],
  (listsIdsForBulkUpdate: string[]) => listsIdsForBulkUpdate
);

export type MediaResortsIdsForBulkUpdateSelector = (state: RootState) => string[];
export const selectMediaResortsIdsForBulkUpdate: MediaResortsIdsForBulkUpdateSelector = createSelector(
  [(state: RootState) => state.contacts.mediaResortsIdsForBulkUpdate],
  (mediaResortsIdsForBulkUpdate: string[]) => mediaResortsIdsForBulkUpdate
);

export type TopicsIdsForBulkUpdateSelector = (state: RootState) => string[];
export const selectTopicsIdsForBulkUpdate: TopicsIdsForBulkUpdateSelector = createSelector(
  [(state: RootState) => state.contacts.topicsIdsForBulkUpdate],
  (topicsIdsForBulkUpdate: string[]) => topicsIdsForBulkUpdate
);

export type ListsToAddToContactSelector = (state: RootState) => List[];
export const selectListsToAddToContact: ListsToAddToContactSelector = createSelector(
  [(state: RootState) => state.contacts.listsToAddToContact],
  (listsToAddToContact: List[]) => listsToAddToContact
);

export type MediaResortsForBulkUpdateSelector = (state: RootState) => MediaResort[];
export const selectMediaResortsToAddToContact: MediaResortsForBulkUpdateSelector = createSelector(
  [(state: RootState) => state.contacts.mediaResortsToAddToContact],
  (mediaResortsToAddToContact: MediaResort[]) => mediaResortsToAddToContact
);

export type TopicsForBulkUpdateSelector = (state: RootState) => Topic[];
export const selectTopicsToAddToContact: TopicsForBulkUpdateSelector = createSelector(
  [(state: RootState) => state.contacts.topicsToAddToContact],
  (topicsToAddToContact: Topic[]) => topicsToAddToContact
);

export type AllTagsSelector = (state: RootState) => Tag[];
export const selectAllTags: AllTagsSelector = createSelector([(state: RootState) => state.contacts.allTags], (allTags: Tag[]) => allTags);

export type FilteredTagsInPanelSelector = (state: RootState) => Tag[];
export const selectFilteredTagsInPanel: FilteredTagsInPanelSelector = createSelector(
  [(state: RootState) => state.contacts.filteredTags],
  (filteredTags: Tag[]) => filteredTags
);

export type TagNameToCreateSelector = (state: RootState) => string;
export const selectTagNameToCreate: TagNameToCreateSelector = createSelector(
  [(state: RootState) => state.contacts.tagNameToCreate],
  (tagNameToCreate: string) => tagNameToCreate
);

export type TagsToDeleteSelector = (state: RootState) => Tag[];
export const selectTagsToDelete: TagsToDeleteSelector = createSelector(
  [(state: RootState) => state.contacts.tagsToDelete],
  (tagsToDelete: Tag[]) => tagsToDelete
);

export type tagToAddToContactsSelector = (state: RootState) => TagProjection;
export const selectTagToAddToContacts: tagToAddToContactsSelector = createSelector(
  [(state: RootState) => state.contacts.tagToAddToContacts],
  (tagToAddToContacts: TagProjection) => tagToAddToContacts
);

export type TagToRemoveFromContactsSelector = (state: RootState) => TagProjection;
export const selectTagToRemoveFromContacts: TagToRemoveFromContactsSelector = createSelector(
  [(state: RootState) => state.contacts.tagToRemoveFromContacts],
  (tagToRemoveFromContacts: TagProjection) => tagToRemoveFromContacts
);

export type ContactsIdsToAddTagSelector = (state: RootState) => string[];
export const selectContactsIdsToAddTag: ContactsIdsToAddTagSelector = createSelector(
  [(state: RootState) => state.contacts.contactsIdsToAddTag],
  (contactsIdsToAddTag: string[]) => contactsIdsToAddTag
);

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    firstPageOfContactsRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = 1;
      state.totalCountOfContacts = 0;
      state.contacts = [];
    },
    firstPageOfContactsReceived: (state, action: PayloadAction<Contact[]>) => {
      const receivedContacts = action.payload.map((contact) => {
        return {
          ...contact,
          tags: contact.tags.filter((t) => state.allTags.map((tag) => tag.id).includes(t.id))
        };
      });
      state.contacts = receivedContacts;
      state.isLoadingSearchResults = false;

      const topContacts = state.contacts.slice(0, 5);
      state.contactNameSuggestions = Array.from(new Set(topContacts.map((contact) => `${contact.firstName} ${contact.lastName}`)));
      state.roleSuggestions = Array.from(
        new Set(
          topContacts
            .flatMap((contact) => contact.collaborations)
            .filter((collaboration) => collaboration?.jobTitle?.name)
            .map((collaboration) => collaboration.jobTitle.name)
        )
      );
      state.mediumNameSuggestions = Array.from(
        new Set(
          topContacts
            .flatMap((contact) => contact.collaborations)
            .filter((collaboration) => collaboration?.medium?.name)
            .map((collaboration) => collaboration.medium.name)
        )
      );
      state.languageSuggestions = Array.from(
        new Set(
          topContacts
            .flatMap((contact) => contact.preferredLanguages)
            .filter((language) => language?.name)
            .map((language) => language.name)
        )
      );
      state.resortSuggestions = Array.from(
        new Set(
          topContacts
            .flatMap((contact) => contact.mediaResorts)
            .filter((mediaResort) => mediaResort?.name)
            .map((mediaResort) => mediaResort.name)
        )
      );
      state.tagNameSuggestions = Array.from(new Set(topContacts.flatMap((contact) => contact.tags).map((tag) => tag.name)));
    },
    nextPageOfContactsRequested: (state) => {
      state.isLoadingSearchResults = true;
      state.pageNumber = state.pageNumber + 1;
    },
    nextPageOfContactsReceived: (state, action: PayloadAction<Contact[]>) => {
      const receivedContacts = action.payload.map((contact) => {
        return { ...contact, tags: contact.tags.filter((t) => state.allTags.map((tag) => tag.id).includes(t.id)) };
      });
      state.contacts = [...state.contacts, ...receivedContacts];
      state.isLoadingSearchResults = false;
    },
    setTotalCountOfContacts: (state, action: PayloadAction<number>) => {
      state.totalCountOfContacts = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    contactSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.searchSuggestionsText = action.payload;
      state.isLoadingSearchSuggestions = true;
    },
    contactSuggestionsReceived: (state, action: PayloadAction<SearchSuggestionCategory[]>) => {
      state.contactSuggestions = action.payload;
      state.isLoadingSearchSuggestions = false;
    },
    resetContactSuggestions: (state) => {
      state.contactSuggestions = [];
      state.searchSuggestionsText = '';
    },
    addContactsFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = [...state.filterItems, action.payload];
    },
    removeContactsFilter: (state, action: PayloadAction<FilterItem>) => {
      state.filterItems = state.filterItems.filter((item) => item.fieldName !== action.payload.fieldName || item.value !== action.payload.value);
    },
    clearAllFilters: (state) => {
      state.filterItems = [];
      state.contactNameSuggestions = [];
      state.roleSuggestions = [];
      state.languageSuggestions = [];
      state.resortSuggestions = [];
      state.mediumNameSuggestions = [];
      state.tagNameSuggestions = [];
    },
    startLoadingContactNameSuggestions: (state) => {
      state.contactNameSuggestions = [];
      state.isLoadingContactNameSuggestions = true;
    },
    contactNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    contactNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.contactNameSuggestions = action.payload;
      state.isLoadingContactNameSuggestions = false;
    },
    startLoadingRoleSuggestions: (state) => {
      state.roleSuggestions = [];
      state.isLoadingRoleSuggestions = true;
    },
    roleFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    roleFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.roleSuggestions = action.payload;
      state.isLoadingRoleSuggestions = false;
    },
    startLoadingMediumNameSuggestions: (state) => {
      state.mediumNameSuggestions = [];
      state.isLoadingMediumNameSuggestions = true;
    },
    mediumNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    mediumNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.mediumNameSuggestions = action.payload;
      state.isLoadingMediumNameSuggestions = false;
    },
    startLoadingResortSuggestions: (state) => {
      state.resortSuggestions = [];
      state.isLoadingResortSuggestions = true;
    },
    resortFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    resortFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.resortSuggestions = action.payload;
      state.isLoadingResortSuggestions = false;
    },
    startLoadingLanguageSuggestions: (state) => {
      state.languageSuggestions = [];
      state.isLoadingLanguageSuggestions = true;
    },
    languageFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    languageFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.languageSuggestions = action.payload;
      state.isLoadingLanguageSuggestions = false;
    },
    mediaColumnFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    startLoadingTagNameSuggestions: (state) => {
      state.tagNameSuggestions = [];
      state.isLoadingTagSuggestions = true;
    },
    tagNameFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    tagNameFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.tagNameSuggestions = action.payload;
      state.isLoadingTagSuggestions = false;
    },
    startLoadingCountrySuggestions: (state) => {
      state.countrySuggestions = [];
      state.isLoadingCountrySuggestions = true;
    },
    setLoadingCountrySuggestions: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCountrySuggestions = action.payload;
    },
    countryFilterSuggestionsRequested: (state, action: PayloadAction<string>) => {
      state.filterSuggestionsSearchText = action.payload;
    },
    countryFilterSuggestionsReceived: (state, action: PayloadAction<string[]>) => {
      state.countrySuggestions = action.payload;
      state.isLoadingCountrySuggestions = false;
    },
    setFilters: (state, action: PayloadAction<FilterItem[]>) => {
      state.filterItems = action.payload;
    },
    setQueryParams: (state, action: PayloadAction<QueryParams>) => {
      state.searchText = action.payload.searchText;
      state.filterItems = action.payload.filterItems;
    },
    removeContactRequested: (state, action: PayloadAction<Contact>) => {
      state.contactToRemove = action.payload;
    },
    removedContact: (state, action: PayloadAction<string>) => {
      state.contacts = state.contacts?.filter((contact) => contact.id !== action.payload);
    },
    removeContactFromStore: (state, action: PayloadAction<Contact>) => {
      state.contacts = state.contacts?.filter((contact) => contact.id !== action.payload.id);
    },
    undoRemoveContactFromStore: (state, action: PayloadAction<Contact>) => {
      state.contacts = [action.payload, ...state.contacts];
    },
    removeContactsFromStore: (state, action: PayloadAction<string[]>) => {
      state.previousContacts = state.contacts;
      state.contacts = state.contacts?.filter((contact) => !action.payload.some((id) => id === contact.id));
    },
    undoRemoveContactsFromStore: (state) => {
      state.contacts = state.previousContacts;
      state.previousContacts = [];
    },
    updateContactInTable: (state, action: PayloadAction<Contact>) => {
      const contactToUpdateIndex = state.contacts.findIndex((c) => c.id === action.payload.id);
      state.contactPreviousValue = cloneDeep(state.contacts[contactToUpdateIndex]);
      state.contacts[contactToUpdateIndex] = action.payload;
    },
    updateCollaborationInTable: (state, action: PayloadAction<{ contactId: string; collaboration: Collaboration }>) => {
      const contactToUpdateIndex = state.contacts.findIndex((c) => c.id === action.payload.contactId);
      state.contactPreviousValue = cloneDeep(state.contacts[contactToUpdateIndex]);

      const index = state.contacts[contactToUpdateIndex].collaborations.findIndex((c) => c.id === action.payload.collaboration.id);

      state.contacts[contactToUpdateIndex].collaborations = [
        ...state.contacts[contactToUpdateIndex].collaborations.slice(0, index),
        action.payload.collaboration,
        ...state.contacts[contactToUpdateIndex].collaborations.slice(index + 1)
      ];
    },
    createTopicRequested: (state, action: PayloadAction<Topic>) => {
      state.topicToAdd = action.payload;
    },
    topicCreated: (state, action: PayloadAction<Topic>) => {
      state.topicsToAddToContact = [...state.topicsToAddToContact, action.payload];
    },
    createMediaResortRequested: (state, action: PayloadAction<Topic>) => {
      state.mediaResortToAdd = action.payload;
    },
    mediaResortCreated: (state, action: PayloadAction<Topic>) => {
      state.mediaResortsToAddToContact = [...state.mediaResortsToAddToContact, action.payload];
    },
    addResortInTable: (state, action: PayloadAction<{ contactIds: string[]; resorts: MediaResort[] }>) => {
      const { contactIds, resorts } = action.payload;
      contactIds.forEach((contactId) => {
        const contactToUpdate = state.contacts.find((c) => c.id === contactId);
        state.contactPreviousValue = cloneDeep(contactToUpdate);

        contactToUpdate.mediaResorts = [...contactToUpdate.mediaResorts, ...resorts];
      });
    },
    removeResortInTable: (state, action: PayloadAction<{ contactIds: string[]; resorts: MediaResort[] }>) => {
      const { contactIds, resorts } = action.payload;
      contactIds.forEach((contactId) => {
        const contactToUpdate = state.contacts.find((c) => c.id === contactId);
        state.contactPreviousValue = cloneDeep(contactToUpdate);

        contactToUpdate.mediaResorts = contactToUpdate.mediaResorts.filter((mr) => !resorts.some((resort) => resort.id === mr.id));
      });
    },
    removeResortsInTable: (state, action: PayloadAction<string[]>) => {
      const mediaResortsIds = action.payload;
      const idsOfContactsToUpdate = state.contacts
        .filter((contact) => contact.mediaResorts.some((mr) => mediaResortsIds.includes(mr.id)))
        .map((contact) => contact.id);

      idsOfContactsToUpdate.forEach((id) => {
        const contact = state.contacts.find((contact) => contact.id === id);
        contact.mediaResorts = contact.mediaResorts.filter((mr) => !mediaResortsIds.includes(mr.id));
      });
    },
    undoUpdateContactInTable: (state) => {
      const contactToUpdateIndex = state.contacts.findIndex((c) => c.id === state.contactPreviousValue.id);
      state.contacts[contactToUpdateIndex] = state.contactPreviousValue;
    },
    addedTagToContactsSearchResults: (state, action: PayloadAction<{ contactIds: string[]; tag: Tag }>) => {
      action.payload.contactIds.forEach((element) => {
        const index = state.contacts.findIndex((c) => c.id === element);
        if (state.contacts[index].tags.map((t) => t.id).includes(action.payload.tag.id)) {
          return;
        }
        state.contacts[index] = {
          ...state.contacts[index],
          tags: [...state.contacts[index].tags, action.payload.tag]
        };
      });
    },
    removedTagsFromContactsSearchResults: (state, action: PayloadAction<{ contactIds: string[]; tags: Tag[] }>) => {
      action.payload.contactIds.forEach((element) => {
        const index = state.contacts.findIndex((c) => c.id === element);
        action.payload.tags.forEach((tag) => {
          state.contacts[index] = {
            ...state.contacts[index],
            tags: state.contacts[index].tags.filter((t) => t.id !== tag.id)
          };
        });
      });
    },
    updatedProfilePictureSearchResults: (state, action: PayloadAction<{ contactId: string; imageUrl: string }>) => {
      const index = state.contacts.findIndex((c) => c.id === action.payload.contactId);
      state.contacts[index] = { ...state.contacts[index], profilePictureUrl: action.payload.imageUrl };
    },
    setSearchResultTableHeader: (state, action: PayloadAction<TableHeader[]>) => {
      state.searchResultsTableHeader = action.payload;
      state.exportHeader = action.payload.filter((headerItem) => headerItem.isColumnVisible && headerItem.fieldName !== 'add');
    },
    startSearchAnimation: (state) => {
      state.isSearchAnimationActive = true;
    },
    stopSearchAnimation: (state) => {
      state.isSearchAnimationActive = false;
    },
    openSavedSearchesPanel: (state) => {
      state.isSavedSearchesPanelOpen = true;
    },
    closeSavedSearchesPanel: (state) => {
      state.isSavedSearchesPanelOpen = false;
    },
    openFiltersPanel: (state) => {
      state.isFiltersPanelOpen = true;
    },
    closeFiltersPanel: (state) => {
      state.isFiltersPanelOpen = false;
    },
    setSortingInput: (state, action: PayloadAction<ISortingInput>) => {
      state.sortingInput = action.payload;
    },
    removeContactsByIdsRequested: (state, action: PayloadAction<string[]>) => {
      state.contactsToRemoveIds = action.payload;
    },
    contactsByIdsRemoved: (state, action: PayloadAction<Contact[]>) => {
      state.contacts = state.contacts.filter((c) => !action.payload.map((p) => p.id).includes(c.id));
      state.contactsToRemoveIds = [];
      state.totalCountOfContacts = state.totalCountOfContacts - action.payload.length;
    },
    openUpdateDialog: (state) => {
      state.isUpdateDialogVisible = true;
    },
    closeUpdateDialog: (state) => {
      state.isUpdateDialogVisible = false;
      state.listsIdsForBulkUpdate = [];
      state.mediaResortsIdsForBulkUpdate = [];
      state.topicsIdsForBulkUpdate = [];
      state.listsToAddToContact = [];
      state.mediaResortsToAddToContact = [];
      state.topicsToAddToContact = [];
    },
    updateContactsRequested: (state, action: PayloadAction<{ topicsIds: string[]; mediaResortsIds: string[]; listsIds: string[] }>) => {
      state.listsIdsForBulkUpdate = action.payload.listsIds;
      state.mediaResortsIdsForBulkUpdate = action.payload.mediaResortsIds;
      state.topicsIdsForBulkUpdate = action.payload.topicsIds;
    },
    addTopicForBulkUpdate: (state, action: PayloadAction<Topic>) => {
      state.topicsToAddToContact = [...state.topicsToAddToContact, action.payload];
    },
    addMediaResortForBulkUpdate: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortsToAddToContact = [...state.mediaResortsToAddToContact, action.payload];
    },
    addListForBulkUpdate: (state, action: PayloadAction<List>) => {
      state.listsToAddToContact = [...state.listsToAddToContact, action.payload];
    },
    removeTopicForBulkUpdate: (state, action: PayloadAction<Topic>) => {
      state.topicsToAddToContact = state.topicsToAddToContact.filter((t) => t.id !== action.payload.id);
    },
    removeMediaResortForBulkUpdate: (state, action: PayloadAction<MediaResort>) => {
      state.mediaResortsToAddToContact = state.mediaResortsToAddToContact.filter((t) => t.id !== action.payload.id);
    },
    removeListForBulkUpdate: (state, action: PayloadAction<List>) => {
      state.listsToAddToContact = state.listsToAddToContact.filter((t) => t.id !== action.payload.id);
    },
    addNewTagRequested: (state, action: PayloadAction<{ selectedContactIds: string[]; text: string }>) => {
      state.tagNameToCreate = action.payload.text;
    },
    addedNewTag: (state, action: PayloadAction<Tag>) => {
      state.allTags = [...state.allTags, action.payload];
      state.filteredTags = state.allTags;
      state.selectedContacts = state.selectedContacts.map((c) => {
        return !c.tags.includes(action.payload) ? (c = { ...c, tags: [...c.tags, action.payload] }) : c;
      });
      state.tagNameToCreate = '';
    },
    deleteTagsRequested: (state, action: PayloadAction<Tag[]>) => {
      state.tagsToDelete = action.payload;
    },
    tagsDeleted: (state, action: PayloadAction<string[]>) => {
      state.allTags = state.allTags.filter((tag) => !action.payload.includes(tag.id));
      state.filteredTags = state.filteredTags.filter((tag) => !action.payload.includes(tag.id));
      state.selectedContacts = state.selectedContacts.map((c) => (c = { ...c, tags: c.tags.filter((tag) => !action.payload.includes(tag.id)) }));
      state.tagsToDelete = [];
    },
    addTagToContactsRequested: (state, action: PayloadAction<{ selectedContactIds: string[]; tag: Tag }>) => {
      state.contactsIdsToAddTag = action.payload.selectedContactIds;
      state.tagToAddToContacts = action.payload.tag;
    },
    addTagToContactsInStore: (state, action: PayloadAction<{ selectedContactIds: string[]; tag: Tag }>) => {
      state.selectedContacts = state.contacts.filter((contact) => action.payload.selectedContactIds.includes(contact.id));
      state.selectedContacts = state.selectedContacts.map((contact) => ({ ...contact, tags: [...contact.tags, action.payload.tag] }));
    },
    removeTagFromContactsInStore: (state, action: PayloadAction<{ selectedContactIds: string[]; tag: Tag }>) => {
      state.selectedContacts = state.contacts.filter((contact) => action.payload.selectedContactIds.includes(contact.id));
      state.selectedContacts = state.selectedContacts.map((contact) => ({ ...contact, tags: contact.tags.filter((tag) => tag.id !== action.payload.tag.id) }));
    },
    removeTagFromContactsRequested: (state, action: PayloadAction<{ selectedContactIds: string[]; tag: Tag }>) => {
      state.selectedContacts = state.contacts.filter((contact) => action.payload.selectedContactIds.includes(contact.id));
      state.tagToRemoveFromContacts = action.payload.tag;
    },
    filterTags: (state, action: PayloadAction<string>) => {
      state.filteredTags = state.allTags.filter((tag) => tag.name.includes(action.payload));
    },
    getAllContactsTagsRequested: () => {},
    allTagsReceived: (state, action: PayloadAction<Tag[]>) => {
      state.allTags = action.payload;
      state.filteredTags = action.payload;
    },
    addedCollaborationToSearchResults: (state, action: PayloadAction<{ collaboration: Collaboration; contactId: string }>) => {
      const { collaboration, contactId } = action.payload;

      state.contacts = state.contacts.map((contact) => {
        if (contact.id !== contactId) return contact;

        return { ...contact, collaborations: [...contact.collaborations, collaboration] };
      });
    },
    removedCollaborationFromSearchResults: (state, action: PayloadAction<{ collaborationId: string; contactId: string }>) => {
      const { collaborationId, contactId } = action.payload;

      state.contacts = state.contacts.map((contact) => {
        if (contact.id !== contactId) return contact;

        return { ...contact, collaborations: contact.collaborations.filter((collaboration) => collaboration.id !== collaborationId) };
      });
    },
    updatedContactsSearchResults: (state, action: PayloadAction<Contact>) => {
      const index = state.contacts.findIndex((c) => c.id === action.payload.id);
      state.contacts[index] = action.payload;
    },
    createListRequested: (state, action: PayloadAction<string>) => {
      state.listNameToAdd = action.payload;
    },
    listCreated: (state, action: PayloadAction<List>) => {
      state.listsToAddToContact = [...state.listsToAddToContact, action.payload];
    },
    removedMediaResorts: (state, action: PayloadAction<string[]>) => {
      state.mediaResortsToAddToContact = state.mediaResortsToAddToContact.filter((mr) => !action.payload.includes(mr.id));
    }
  }
});

export const {
  firstPageOfContactsRequested,
  firstPageOfContactsReceived,
  nextPageOfContactsReceived,
  nextPageOfContactsRequested,
  setTotalCountOfContacts,
  contactSuggestionsReceived,
  contactSuggestionsRequested,
  resetContactSuggestions,
  addContactsFilter,
  clearAllFilters,
  setFilters,
  removeContactsFilter,
  removeContactRequested,
  removedContact,
  removeContactFromStore,
  undoRemoveContactFromStore,
  removeContactsFromStore,
  undoRemoveContactsFromStore,
  updateContactInTable,
  undoUpdateContactInTable,
  updateCollaborationInTable,
  addResortInTable,
  removeResortInTable,
  addedTagToContactsSearchResults,
  updatedProfilePictureSearchResults,
  setSearchResultTableHeader,
  setSearchText,
  startSearchAnimation,
  stopSearchAnimation,
  openSavedSearchesPanel,
  closeSavedSearchesPanel,
  openFiltersPanel,
  closeFiltersPanel,
  setQueryParams,
  contactNameFilterSuggestionsReceived,
  contactNameFilterSuggestionsRequested,
  languageFilterSuggestionsReceived,
  languageFilterSuggestionsRequested,
  mediaColumnFilterSuggestionsRequested,
  mediumNameFilterSuggestionsReceived,
  mediumNameFilterSuggestionsRequested,
  resortFilterSuggestionsReceived,
  resortFilterSuggestionsRequested,
  roleFilterSuggestionsReceived,
  roleFilterSuggestionsRequested,
  startLoadingContactNameSuggestions,
  startLoadingLanguageSuggestions,
  startLoadingMediumNameSuggestions,
  startLoadingResortSuggestions,
  startLoadingRoleSuggestions,
  startLoadingTagNameSuggestions,
  tagNameFilterSuggestionsReceived,
  tagNameFilterSuggestionsRequested,
  startLoadingCountrySuggestions,
  countryFilterSuggestionsRequested,
  countryFilterSuggestionsReceived,
  setLoadingCountrySuggestions,
  setSortingInput,
  removeContactsByIdsRequested,
  contactsByIdsRemoved,
  openUpdateDialog,
  closeUpdateDialog,
  updateContactsRequested,
  addTopicForBulkUpdate,
  addMediaResortForBulkUpdate,
  addListForBulkUpdate,
  removeTopicForBulkUpdate,
  removeMediaResortForBulkUpdate,
  removeListForBulkUpdate,
  removedTagsFromContactsSearchResults,
  addNewTagRequested,
  addedNewTag,
  deleteTagsRequested,
  tagsDeleted,
  addTagToContactsRequested,
  addTagToContactsInStore,
  removeTagFromContactsInStore,
  removeTagFromContactsRequested,
  filterTags,
  getAllContactsTagsRequested,
  allTagsReceived,
  addedCollaborationToSearchResults,
  removedCollaborationFromSearchResults,
  removeResortsInTable,
  updatedContactsSearchResults,
  createMediaResortRequested,
  createTopicRequested,
  mediaResortCreated,
  topicCreated,
  createListRequested,
  listCreated,
  removedMediaResorts
} = contactsSlice.actions;

export default contactsSlice.reducer;
